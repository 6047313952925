import React, { useImperativeHandle } from "react";
import classNames from "classnames";
import { useSnapCarousel } from "../src/use-snap-carousel";
import "./reset.css";
const styles = require("./carousel.module.css");

/**
 * This is an example Carousel built on top of `useSnapCarousel`
 */

export const Carousel = React.forwardRef(function Carousel(
  {
    scrollRef,
    next,
    prev,
    goTo,
    pages,
    activePageIndex,
    snapPointIndexes,
    refresh,
    axis,
    items,
    renderItem,
    scrollMargin = false,
  },
  ref
) {
  useImperativeHandle(ref, () => ({ refresh }));

  return (
    <div
      className={classNames(styles.root, {
        [styles.x]: axis === "x",
        [styles.y]: axis === "y",
        [styles.scrollMargin]: scrollMargin,
      })}
    >
      <ul className={styles.scroll} ref={scrollRef}>
        {items.map((item, index) =>
          renderItem({
            item,
            index,
            isSnapPoint: snapPointIndexes.has(index),
          })
        )}
      </ul>
      <div className={styles.controls}>
        <button
          disabled={activePageIndex <= 0}
          onClick={() => prev()}
          className={styles.prevButton}
        >
          {String.fromCharCode(8592)}
        </button>
        <ol className={styles.pagination}>
          {pages.map((_, i) => (
            <li
              key={i}
              className={classNames(styles.paginationItem, {
                [styles.paginationItemActive]: i === activePageIndex,
              })}
            >
              <button
                className={styles.paginationButton}
                onClick={() => goTo(i)}
              >
                {i + 1}
              </button>
            </li>
          ))}
        </ol>
        <button
          disabled={activePageIndex === pages.length - 1}
          onClick={() => next()}
          className={styles.nextButton}
        >
          {String.fromCharCode(8594)}
        </button>
      </div>
    </div>
  );
});

export const CarouselItem = function CarouselItem({
  isSnapPoint,
  bgColor,
  width,
  children,
}) {
  return (
    <li
      className={styles.item}
      style={{
        backgroundColor: bgColor,
        width: width || "",
        scrollSnapAlign: isSnapPoint ? "start" : "",
      }}
    >
      {children}
    </li>
  );
};
