import { getCDNUrl } from "./HelperFunctions";
import { Telco } from "./telco";

export const Globals = { og: {}, login: {}, pk: {}, appUrl: {} };

Globals.isTesting = false;
Globals.SocialOnboardingAPI = "https://pk.cricwick.net/";

// *********************** Staging ***************************

//Globals.UserManagment = "http://34.217.97.54:8001/"; // staging
// Globals.redemptionService = "http://34.222.208.195:4005/api/v1/";      //staging
// Globals.UCS = "http://34.222.208.195:4004/api/v1/";   // staging
// Globals.FantasyWebUrl = "http://localhost:3001/"; // local

// ********************** Production **************************
Globals.FantasyWebUrl = "https://fantasy.cricwick.net/"; // production
Globals.UserManagment = "https://ums-prod.cricwick.net/"; // production
//Globals.redemptionService = "http://urd.cricwick.net/api/v1/";         //PROD
Globals.redemptionService = "https://apigw.cricwick.net/api/v1/"; //PROD 1
Globals.UCS = "https://ucs.cricwick.net/api/v1/"; //PROD

Globals.appUrl = {
  // will only be used for cricwick 2020
  pk: {
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    ios: "https://apps.apple.com/us/app/cricwick-pakistan/id1461475044",
  },
  bh: {
    android:
      "https://play.google.com/store/apps/details?id=com.cricwick.bahrain",
    ios: "https://apps.apple.com/us/app/cricwick-bahrain/id1499601119",
  },
  sa: {
    android: "https://play.google.com/store/apps/details?id=com.cricwick.ksa",
    ios: "https://apps.apple.com/us/app/cricwick/id1436495759",
  },
  ae: {
    android: "https://play.google.com/store/apps/details?id=com.cricwick.uae",
    ios: "https://apps.apple.com/us/app/cricwick-uae/id1499604367",
  },
  kw: {
    android:
      "https://play.google.com/store/apps/details?id=com.cricwick.kuwait",
    ios: "https://apps.apple.com/us/app/cricwick-kuwait/id1499603060",
  },
  om: {
    android: "https://play.google.com/store/apps/details?id=com.cricwick.oman",
    ios: "https://apps.apple.com/us/app/cricwick-pakistan/id1461475044",
  },
  lk: {
    android:
      "https://play.google.com/store/apps/details?id=com.cricwick.srilanka",
    ios: "https://apps.apple.com/us/app/cricwick-srilanka/id1499604713",
  },
};
// !!!!!!!!!!!~~~~~CREATE A FILE AND ADD TELCO~~~~~!!!!!!!!!!!
// !!!!!!!!!!!  src/telco.js                       !!!!!!!!!!!
// !!!!!!!!!!!  export const Telco = "mobilink";   !!!!!!!!!!!

let version = "2.0";

if (Telco === "mobilink") {
  //Globals.ApiUrl = "http://testing.cricwick.net:8080/";//staging
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  //Globals.ApiUrl = "http://ops.cricwick.net:8080/" //ops
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "https://staging.cricwick.net:13002/"; //production
  // Globals.NodeApi = "http://localhost:3002/";
  //Globals.NodeApi = "https://staging.cricwick.net:13005/"; //ssl staging

  Globals.ssl = false;
  Globals.firebase = false;

  Globals.allowLowBalance = true;

  Globals.logo = getCDNUrl("/images/mobilink/jazz_logo.png?v=1");
  Globals.imgRightAppDownload = getCDNUrl("/images/mobilink/app_download.png");
  Globals.title =
    "Jazz Cricket - Enjoy live cricket and watch key cricket moments";
  Globals.telco = Telco;
  Globals.cssFor = Telco;
  Globals.product = "Jazz Cricket";
  Globals.ios =
    "https://itunes.apple.com/pk/app/jazz-cricket/id1371362811?mt=8";
  Globals.androidMarket = "market://details?id=com.Khaleef.CricWickMobilink";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.Khaleef.CricWickMobilink";

  Globals.og.title = "Jazz Cricket";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "/images/mobilink/og-image.jpg";

  Globals.regex = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin =
    "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.";
  Globals.login.image = "/images/mobilink/JazzCrick_WebSignIn.jpg";
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/mobilink/placeholder4x3.jpg";
  Globals.placeholder16x9 = getCDNUrl("/images/mobilink/placeholder16x9.jpg");
  Globals.placeholderVideo4x3 = "/images/mobilink/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = getCDNUrl(
    "/images/mobilink/placeholderPlain4x3.jpg"
  );

  Globals.hbl_psl = "/images/hbl-psl.png";
  Globals.psl_banner = "/images/psl-banner.png";

  Globals.isLiveAllowed = true;
  Globals.appName = "JazzCricketWeb";
} else if (Telco === "ufone") {
  Globals.regex = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;

  //Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  // Globals.ApiUrl = "http://ops.cricwick.net:8080/" //ops
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "https://staging.cricwick.net:13002/";
  //  Globals.NodeApi = "http://localhost:3002/";

  Globals.ssl = false;
  Globals.firebase = false;

  Globals.allowLowBalance = true;

  Globals.logo = getCDNUrl("/images/ufone/ufone_logo.jpg?v=1");
  Globals.imgRightAppDownload = "/images/ufone/app_download.png";
  Globals.title =
    "Ufone Cricket - Enjoy live cricket and watch key cricket moments";
  Globals.telco = Telco;
  Globals.cssFor = Telco;
  Globals.product = "Ufone Cricket";
  Globals.ios = "https://itunes.apple.com/pk/app/ufone-cricket/id1380145518";
  Globals.androidMarket = "market://details?id=com.ufone.cricket";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.ufone.cricket";

  Globals.og.title = "Ufone Cricket";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "/images/ufone/og-image.jpg";

  Globals.disableSideLogin = true;
  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.SubscriptionPackages = true;
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin =
    "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.";
  Globals.login.image = getCDNUrl("/images/ufone/img-download.jpg");
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/ufone/placeholder4x3.jpg";
  Globals.placeholder16x9 = getCDNUrl("/images/ufone/placeholder16x9.jpg");
  Globals.placeholderVideo4x3 = "/images/ufone/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = getCDNUrl(
    "/images/ufone/placeholderPlain4x3.jpg"
  );

  Globals.hbl_psl = "/images/hbl-psl.png";
  Globals.psl_banner = "/images/psl-banner.png";

  Globals.appName = "CricwickWeb";
} else if (Telco === "zain") {
  //zain ksa; cric.sa.zain.com
  //Globals.ApiUrl = "http://ops.cricwick.net:8080/" //ops
  //Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "http://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "https://staging.cricwick.net:13002/";
  // Globals.NodeApi = "http://localhost:3002/";

  Globals.ssl = false;
  Globals.firebase = false;

  Globals.allowLowBalance = true;

  Globals.logo = getCDNUrl("/images/cricwick/cricwick-logo.jpg?v=3");
  Globals.telcoLogo = getCDNUrl("/images/zain/telco_logo.png");
  Globals.imgRightAppDownload = "/images/zain/cric-sites-web-banner.jpg?v=11";
  Globals.title = "Cricwick - Enjoy live cricket and watch key cricket moments";

  Globals.telco = Telco;
  Globals.cssFor = Telco;

  Globals.product = "Cricwick";
  Globals.ios =
    "https://itunes.apple.com/us/app/cricwick/id1436495759?ls=1&mt=8";
  Globals.androidMarket =
    "https://play.google.com/store/apps/details?id=com.cricwick.ksa";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.cricwick.ksa";

  Globals.og.title = "Cricwick";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "";

  Globals.regex = /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/;
  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "966 xxx xxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin = "Please enter the Pin code you received in SMS.";
  Globals.login.image =
    getCDNUrl("/images/zain/CricwickKSA_WebSignIn.jpg?v=") + version;
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/zain/placeholder4x3.jpg";
  Globals.placeholder16x9 = getCDNUrl("/images/zain/placeholder16x9.jpg");
  Globals.placeholderVideo4x3 = "/images/zain/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = getCDNUrl(
    "/images/zain/placeholderPlain4x3.jpg"
  );
  Globals.login.price_points =
    "The service price is 7 SAR per week and renewed weekly. To cancel your subscription please send UCWW to 700116.";
  Globals.hbl_psl = "/images/hbl-psl.png";
  Globals.psl_banner = "/images/psl-banner.png";

  Globals.appName = "CricwickMena";
} else if (Telco === "zong") {
  //Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  //Globals.ApiUrl = "http://ops.cricwick.net:8080/" //ops
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "https://staging.cricwick.net:13002/";
  //Globals.NodeApi = "http://localhost:3002/";

  Globals.ssl = false;
  Globals.firebase = false;

  Globals.secondServer = true;

  Globals.allowLowBalance = true;

  Globals.logo = getCDNUrl("/images/zong/zong_logo.png?v=1");
  Globals.imgRightAppDownload = "/images/zong/app_download.png";
  Globals.title =
    "Zong Cricket - Enjoy live cricket and watch key cricket moments";
  Globals.telco = Telco;
  Globals.cssFor = Telco;
  Globals.product = "Zong Cricket";

  Globals.ios = "https://apps.apple.com/us/app/zong-cricket/id1466767033?ls=1";
  Globals.androidMarket = "market://details?id=com.zong.cricket";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.zong.cricket";

  //   Globals.ios = "#";
  //   Globals.androidMarket = '#';
  //   Globals.android = '#';

  Globals.og.title = "Zong Cricket";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "/images/zong/og-image.jpg";

  Globals.regex = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin =
    "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.";
  Globals.login.image = getCDNUrl("/images/zong/img-download.jpg");
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/zong/placeholder4x3.jpg";
  Globals.placeholder16x9 = getCDNUrl("/images/zong/placeholder16x9.jpg");
  Globals.placeholderVideo4x3 = "/images/zong/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = getCDNUrl(
    "/images/zong/placeholderPlain4x3.jpg"
  );
  Globals.hbl_psl = "/images/hbl-psl.png";
  Globals.psl_banner = "/images/psl-banner.png";

  Globals.appName = "CricwickWeb";
  //------------------------ End Zong -------------------------------------------
} else {
  //Globals.ApiUrl = "http://ops.cricwick.net:8080/" //ops
  //Globals.ApiUrl = "http://testing.cricwick.net:8080/"; //staging
  //Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "https://cwscoring.cricwick.net/"; //live
  //Globals.ApiUrl = "https://back.cricwick.net/"
  // Globals.ApiUrl = "http://18.237.83.21:8080/"; // staging
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  // Globals.Api2 = "https://mobisub.cricwick.mobi/main/"; // live
  Globals.Api2 = "http://18.196.28.174:9000/"; // staging
  //Globals.Api2 = "http://mobisub.cricwick.mobi/"; //PROD
  // Globals.NodeApi = "http://localhost:3002/"; //local
  Globals.NodeApi = "https://staging.cricwick.net:13002/"; //ssl live
  //Globals.NodeApi = "https://staging.cricwick.net:13005/"; //ssl staging

  Globals.ssl = false;
  Globals.firebase = false;

  Globals.allowLowBalance = true;

  Globals.logo = getCDNUrl("/images/cricwick/cricwick-logo.jpg?v=3");
  Globals.imgRightAppDownload =
    "/images/cricwick/cric-sites-web-banner.jpg?v=" + version;
  Globals.title =
    "Get the Latest Cricket Action and Watch Key Moments on Cricwick";

  Globals.telco = "";
  Globals.cssFor = Telco;

  Globals.product = "Cricwick";

  // Globals.ios = 'market://';
  Globals.ios =
    "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick";
  Globals.androidMarket = "market://details?id=com.Khaleef.cricwick";
  Globals.pk.ios =
    "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong";
  Globals.pk.android =
    "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong";
  Globals.pk.androidMarket =
    "market://details?id=com.Khaleef.CricWick.TelenorZong";

  Globals.og.title =
    "Watch live Cricket, play fantasy game & get exclusive Cricket content - Cricwick";
  Globals.og.description =
    "A Complete and engaging Cricket experience. Subscribe now for live streaming, fantasy game, Ball-by-Ball scores & live updates, latest Cricket news & editorials, exclusive video content, interviews, cricket trivia, and match highlights.";
  Globals.og.image = "/images/cricwick/og-image.png";

  Globals.login.enterNumber = "Enter Phone Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin = "Please enter the Pin code you received in SMS.";
  Globals.login.image =
    getCDNUrl("/images/cricwick/cricwickInnerAppBanner.jpg?v=") + version;
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;
  Globals.login.chooseTelco = "Please Select Your Network Operator";
  // Globals.login.chooseTelco = "Please Choose Your Operator";
  Globals.hbl_psl = "/images/hbl-psl.png";
  Globals.psl_banner = "/images/psl-banner.png";
  Globals.telcos = [
    {
      telco: "telenor",
      countryCode: "pk",
      name: "Telenor",
      b: "mobilink",
      flag: "/images/flags/pak.jpg",
      telco_message: "Service Charges: Rs.2.5+Tax/Daily",
      regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
      // telco_message: "*Billing enabled by STC - 0.65SAR / Day",
      login: {
        enterNumber:
          "Enter mobile number to subscribe. After subscription PKR 2+Tax will be charged daily from your mobile number.",
        phonePlaceholder: Globals.login.phonePlaceholder,
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin:
          "Please enter the PIN code received in SMS. Service charges PKR 2+Tax daily will be deducted from your phone balance when you click subscribe.",
        image: Globals.login.image,
        invalidPhone: Globals.login.invalidPhone,
      },
    },
    {
      telco: "zong",
      countryCode: "pk",
      name: "Zong",
      b: "mobilink",
      flag: "/images/flags/pak.jpg",
      telco_message:
        "User will be charged PKR Pkr 7+Tax per week. To unsubscribe send UNSUB to 3557",
      regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: Globals.login.phonePlaceholder,
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        invalidPhone: Globals.login.invalidPhone,
      },
    },
    {
      telco: "ufone",
      countryCode: "pk",
      name: "Ufone",
      b: "ufone",
      flag: "/images/flags/pak.jpg",
      telco_message: "Service Charges: Rs.2.39+Tax/Daily",
      regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
      // telco_message: "*Billing enabled by STC - 0.65SAR / Day",
      login: {
        enterNumber:
          "Enter mobile number to subscribe. After subscription PKR 2+Tax will be charged daily from your mobile number.",
        phonePlaceholder: Globals.login.phonePlaceholder,
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin:
          "Please enter the PIN code received in SMS. Service charges PKR 2+Tax daily will be deducted from your phone balance when you click subscribe.",
        image: Globals.login.image,
        invalidPhone: Globals.login.invalidPhone,
      },
    },
    {
      telco: "easypaisa",
      countryCode: "pk",
      name: "Easypaisa",
      b: "easypaisa",
      flag: "/images/flags/pak.jpg",
      telco_message: "",
      regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
      login: {
        enterNumber: "Please enter your easypaisa account number",
        phonePlaceholder: Globals.login.phonePlaceholder,
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        invalidPhone: Globals.login.invalidPhone,
      },
    },
    {
      telco: "zain",
      countryCode: "sa",
      name: "Zain",
      b: "zain",
      flag: "/images/flags/ksa.jpg",
      regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
      telco_message:
        "The service price is 7 SAR per week and renewed weekly. To cancel your subscription please send UCWW to 700116.",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "966xxxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 966xxxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`,
      },
    },
    {
      telco: "mobily",
      countryCode: "sa",
      name: "Mobily",
      b: "zain",
      flag: "/images/flags/ksa.jpg",
      regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
      telco_message:
        "The service price is 1 SAR per day and renewed daily. To cancel your subscription please send U9 to 600159.",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "966xxxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`,
      },
    },
    {
      telco: "stc",
      countryCode: "sa",
      name: "STC",
      b: "zain",
      flag: "/images/flags/ksa.jpg",
      regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
      telco_message:
        "The service price is 1 SAR per day and renewed daily. To cancel your subscription please send U 8 to 801755.",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "966xxxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 966xxxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`,
      },
    },
    {
      telco: "virgin",
      countryCode: "sa",
      name: "Virgin",
      b: "zain",
      flag: "/images/flags/ksa.jpg",
      regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
      telco_message:
        "The service price is 0.65 SAR/Day and renewed Daily. To cancel your subscription send U1 to 300329. If you are already a subscriber then we will just verify your phone number.",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "966xxxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 966xxxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`,
      },
    },
    {
      telco: "zain_bh",
      countryCode: "bh",
      name: "Zain",
      b: "zain",
      flag: "/images/flags/bhr.jpg",
      telco_message:
        "You will subscribe to CricWick App for 0.5 BHD weekly. To Unsubscribe send UNSUB 2 to 94005",
      // +"
      // New Users will be given a 1 day free trial, then will be charged the daily rate hereafter."+"
      // To Unsubscribe send UNSUB 1 to 94005 or Use the unsubscribe button in the menu within app"+"
      // Any enquires please contact - info@cricwick.net",
      regex: /^9733\d{7}$|^03\d{7}$|^9661111111\d{2}$|^3\d{7}$/,
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "973xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 973xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 973xxxxxxxx"}`,
      },
    },
    {
      telco: "stc_bahrain",
      countryCode: "bh",
      name: "STC",
      b: "zain",
      flag: "/images/flags/bhr.jpg",
      telco_message:
        "You will be subscribed to Cricwick App for 0.5 BHD/week and will be charged Weekly. To cancel your subscription send UNSUB CW to 98988",
      // +"
      // New Users will be given a 1 day free trial, then will be charged the daily rate hereafter."+"
      // To Unsubscribe send UNSUB 1 to 94005 or Use the unsubscribe button in the menu within app"+"
      // Any enquires please contact - info@cricwick.net",
      regex: /^9733\d{7}$|^03\d{7}$|^9661111111\d{2}$|^3\d{7}$/,
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "973xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 973xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 973xxxxxxxx"}`,
      },
    },
    {
      telco: "zain_kw",
      countryCode: "kw",
      name: "Zain",
      b: "zain",
      flag: "/images/flags/kwt.jpg",
      telco_message:
        "You will subscribe to CricWick App for 0.100 KWD/day. To Unsubscribe send `UNSUB 5` to 93052.",
      regex: /^965[569]\d{7}$/,
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "965xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 965xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 965xxxxxxxx"}`,
      },
    },
    {
      telco: "ooredoo",
      countryCode: "qa",
      name: "Ooredoo",
      b: "zain",
      flag: "/images/flags/qtr.jpg",
      regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
      telco_message: "",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "974xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`,
      },
    },
    {
      telco: "ooredoo_kw",
      countryCode: "kw",
      name: "Ooredoo",
      b: "zain",
      regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
      consentGatewayRedirection: true,
      flag: "/images/flags/kwt.jpg",
      cgRedirectionUrl: window.location.origin + "/authentication/",
      telco_message:
        "You will subscribe to CricWick App for 0.50 KWD daily. To Unsubscribe send STOP1 to 1518.",
      // cgUrl:
      //   "http://galaxylp.mobi-mind.net/?Id=703,CRICKWICK,965,2695,474," +
      //   window.location.origin +
      //   "/authentication/" +
      //   ",0,41903,4545",
      cgUrl: "http://galaxylp.mobi-mind.net/cgparcel/63/koKx",

      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "974xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        //invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`,
      },
    },
    {
      telco: "batelco_bh",
      countryCode: "bh",
      name: "Batelco",
      b: "zain",
      regex: /^9733\d{7}$|^03\d{7}$|^9661111111\d{2}$|^3\d{7}$/,
      consentGatewayRedirection: false,
      flag: "/images/flags/bhr.jpg",
      telco_message:
        "You will subscribe to CricWick for 0.7 BHD/weekly. To unsubscribe, send UNSUB CW to 94456.",
      // cgRedirectionUrl: window.location.origin + "/authentication/",
      // // cgUrl : "http://galaxylp.mobi-mind.net/?Id=701,CRICKWICK,973,2695,45,"+ window.location.origin+"/authentication/" +",0,42601,4542&IURL=http://galaxylp.mobi-mind.net/Cricwick/banner.jpg&MM_languageId=3",
      // cgUrl:
      //   "http://galaxylp.mobi-mind.net/?Id=701,CRICKWICK,973,2695,361," +
      //   window.location.origin +
      //   "/authentication/" +
      //   ",0,42601,4542&IURL=http://galaxylp.mobi-mind.net/Cricwick/banner.jpg&MM_languageId=3",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "973xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 973xxxxxxxx"}`,
      },
    },
    {
      // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
      telco: "viva_kw",
      countryCode: "kw",
      name: "STC", // Changes By Hamza
      b: "zain",
      flag: "/images/flags/kwt.jpg",
      regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
      consentGatewayRedirection: true,
      cgRedirectionUrl: window.location.origin + "/authentication/",
      telco_message: "You will subscribe to CricWick for 100 Fils daily.",
      cgUrl:
        "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,361," +
        window.location.origin +
        "/authentication/" +
        ",0,41904,2395",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "974xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx"  commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`,
      },
    },
    {
      // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
      telco: "etisalat_uae",
      countryCode: "ae",
      name: "Etisalat",
      b: "zain",
      flag: "/images/flags/uae.jpg",
      regex: /^9661111111\d{2}$|971\d{8}$|050\d{6}$|050\d{6}$/,
      consentGatewayRedirection: true,
      cgRedirectionUrl: window.location.origin + "/cgauth/",
      telco_message:
        "This is an ongoing subscription service until you unsubscribe. By subscribing, Etisalat & Du users will be charged 2 AED/daily with one-day FREE trial (available for new users only). Etisalat users can unsubscribe from the service anytime by sending STOP CKT to 1151, Du users can unsubscribe from the service anytime by sending UNSUB  to 8476.",
      cgUrl: "http://etisalat.cricwick.net/login?",
      // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,361,"+ window.location.origin+"/authentication/" +",0,41904,2395",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "971xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx"  commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 971xxxxxxxx"}`,
      },
    },
    {
      telco: "dialog_sl",
      countryCode: "lk",
      name: "Dialog",
      b: "dialog",
      flag: "/images/flags/srilanka.jpg",
      regex: /^94\d{9}$/,
      telco_message: "",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "94xxxxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        invalidPhone: `Please enter phone number in following format ${" 94xxxxxxxxxx"}`,
      },
    },
    {
      // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
      telco: "ooredoo_oman",
      countryCode: "om",
      name: "Ooredoo", // Changes By Hamza
      b: "zain",
      flag: "/images/flags/oman_flag.jpg",
      regex: /^9661111111\d{2}$|968\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
      consentGatewayRedirection: true,
      cgUrl: "http://digitalcg.net/HE?ClientID=9988776655&Channels=99915705",
      // cgRedirectionUrl: window.location.origin + "/authentication/",
      telco_message:
        "Welcome to Cricwick!\n\n OMR 1.313 per week ( Price includes 5% VAT) or proportionally as per your balance. This service renews automatically..\n\n Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, text unsub c to 91971.\n\n To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.\n\n The content inside this service is in English",
      telco_subMessage:
        "OMR 1.313 per week ( Price includes 5% VAT). This service renews automatically.",
      // cgUrl:
      //   "http://galaxylp.mobi-mind.net/Home/Index?Id=701,CRICKWICK,968,2695,361," +
      //   window.location.origin +
      //   "/authentication/" +
      //   ",0,42203,2495&IURL=&MM_Source=MM_App&MM_YY_MSISDN=",
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "968xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx"  commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 968xxxxxxxx"}`,
        prefill: `968`,
        numberLength: 11,
        pinLength: 4,
      },
    },
    {
      // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
      telco: "omantel",
      countryCode: "om",
      name: "Omantel",
      b: "zain",
      flag: "/images/flags/oman_flag.jpg",
      regex: /^9661111111\d{2}$|968\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
      // cgRedirectionUrl: window.location.origin + "/authentication/",
      telco_message: `
        Welcome to CricWick.
        For Omantel users you will be charged 300 Baisa every day (Subject to 5% Value added Tax).
        Your subscription will renew automatically until cancelled.
        To cancel the service, send unsub d to 92804.
        To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.
        The content inside this service is in English.
        `,
      telco_message_ar: `
          مرحبا بكم في CricWick!
          سعر الاشتراك هو 300 بيسة عماني في اليوم (تخضع إلى 5% ضريبة القيمة المضافة) لمشتركي Omantel.
          تتجدد هذه الخدمة تلقائيًا حتى يتم الغاؤها.
          للإلغاء أرسل unsub d إلى 92804 لعملاء Omantel.
          لاستخدام هذه الخدمة، يجب أن يكون عمرك 18 عامًا أو أن تحصل على إذن من والديك أو أي شخص مخول بدفع فاتورتك.
          محتوى هذه الخدمة في اللغة الإنجليزية.
        `,
      telco_subMessage: `300 Baisa every day (Subject to 5% Value added Tax).
      `,
      telco_subMessage_ar: `300 بيسة عماني في اليوم (تخضع إلى 5% ضريبة القيمة المضافة).`,
      login: {
        enterNumber: Globals.login.enterNumber,
        phonePlaceholder: "968xxxxxxxx",
        pinPlaceholder: Globals.login.pinPlaceholder,
        enterPin: Globals.login.enterPin,
        image: Globals.login.image,
        // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx"  commented by Tariq
        invalidPhone: `Please enter phone number in following format ${" 968xxxxxxxx"}`,
        prefill: `968`,
        numberLength: 11,
        pinLength: 4,
      },
    },
  ];

  Globals.placeholder4x3 = "/images/cricwick/placeholder4x3.jpg";
  Globals.placeholder16x9 = "/images/cricwick/placeholder16x9.jpg";
  Globals.placeholderVideo4x3 = "/images/cricwick/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = getCDNUrl(
    "/images/cricwick/placeholderPlain4x3.jpg"
  );

  Globals.appName = "CricwickWeb";
}

// OMAN
// Ooredoo Oman: 96891111111     //cg redirect     AuthenticationForOoredooOman
// Omantel: 96892222222                            AuthenticationForOmantel

// KSA
// Virgin: 966571111111
// STC: 966501111111
// Zain: 966591111111   
// Mobily: 966541111111

// Kuwait
// Zain Kuwait: 96597111111      //sla flow        AuthenticationForZain
// Ooredoo Kuwait: 96566111111
// STC: 966501111111             //cg redirect     Authentication

// Bahrain
// Zain Bahrain: 97336111111  
// STC Bahrain: 97333111111
// Batelco BH: 97338111111      //cg redirect      Authentication

// UAE
// Etisalat UAE: 971561111111   //cg redirect      Authentication
// DU UAE: 971521111111
