import React, { useEffect, useState } from "react";
import { QuizIcon, WinnerBadge } from "./Icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Modal, Box, Card, Alert } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Carousel, CarouselItem } from "./carousel";
import { useSnapCarousel } from "../src/use-snap-carousel";

const baseUrl = "https://quiz.cricwick.net";

const PreviousQuizResult = ({
  setQuiz,
  setShowWinModal,
  showPreviousQuiz,
  setShowPreviousQuiz,
}) => (
  <Alert
    icon={false}
    sx={{ mt: 2, mb: 3 }}
    style={{ width: "100%", backgroundColor: "#FFF3A6", fontSize: "13px" }}
    onClick={() => {
      setQuiz(true);
      setShowPreviousQuiz(!showPreviousQuiz);
    }}
  >
    {showPreviousQuiz ? "Go Back to Quiz  " : "View Previous quiz results  "}{" "}
    <ArrowForwardIosIcon />
  </Alert>
);

const winnerModalStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  borderRadius: "10px",
  bgcolor: "#B91414",
  boxShadow: 24,
  p: 4,
};

const WinModal = ({ open, setOpen, winList }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={winnerModalStyle}>
        <Typography id="modal-modal-title" sx={{ mt: -7 }}>
          <Box
            sx={{
              backgroundColor: "#B91414",
              border: "5px solid #B91414", // Set your desired border color
              borderRadius: "50%", // This creates a circular border
              p: 1, // Add padding to control the size of the border
              display: "inline-block", // Ensure it's inline with the text
            }}
          >
            <WinnerBadge />
          </Box>
        </Typography>
        <Typography
          id="modal-modal-title"
          sx={{
            font: "normal normal bold 16px/22px Open Sans",
            letterSpacing: "0px",
            color: "#FFDD00",
            textTransform: "uppercase",
            opacity: 1,
          }}
          variant="h6"
          component="h2"
        >
          CONGRATULATIONS WINNERS
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 1,
            mb: 2,
            color: "#F1D2D2",
            textAlign: "center",
            font: "normal normal 600 16px/22px Open Sans",
            letterSpacing: "0px",
            opacity: 1,
          }}
        >
          {winList.prize}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 2,
            color: "white",
            textAlign: "center",
            font: "normal normal bold 16px/22px Open Sans",
            letterSpacing: "0px",
            opacity: 1,
            position: "relative",
            paddingLeft: "20px", // Adjust left padding for spacing
            paddingRight: "20px", // Adjust right padding for spacing
          }}
        >
          Today Winners
        </Typography>
        <span
          style={{
            position: "absolute",
            top: "32.5%",
            left: "6%",
            width: "26%",
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.21)", // Left horizontal line color
            transform: "translateY(-50%)", // Vertically center the line
          }}
        />
        <span
          style={{
            position: "absolute",
            top: "32.5%",
            right: "6%",
            width: "26%",
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.21)", // Right horizontal line color
            transform: "translateY(-50%)", // Vertically center the line
          }}
        />
        <div
          style={{
            position: "relative",
          }}
        >
          {winList.winners.map((item) => (
            <>
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 5,
                  mb: 2,
                  color: "white",
                  textAlign: "center",
                  font: "normal normal bold 24px/33px Open Sans",
                  letterSpacing: "0px",
                  opacity: 1,
                }}
              >
                {item}
              </Typography>
              <span
                style={{
                  marginBottom: "2%",
                  position: "absolute",
                  right: "-42%",
                  width: "180%",
                  height: "1px",
                  backgroundColor: "rgba(255, 255, 255, 0.21)", // Right horizontal line color
                  transform: "translateY(-50%)", // Vertically center the line
                }}
              />
            </>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

const WelcomeCard = (props) => {
  const {
    rules,
    setQuiz,
    quizObject,
    quizResults,
    showWinModal,
    setShowWinModal,
    showPreviousQuiz,
    setShowPreviousQuiz,
  } = props;
  return (
    <Box
      sx={{ mt: 1 }}
      style={
        {
          minHeight: "100vh",
          backgroundColor: "#EEF8FE",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        } // You can adjust the height as needed
      }
    >
      {quizResults && (
        <PreviousQuizResult
          setQuiz={setQuiz}
          setShowWinModal={setShowWinModal}
          showPreviousQuiz={showPreviousQuiz}
          setShowPreviousQuiz={setShowPreviousQuiz}
        />
      )}
      <Card
        style={{
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 3px 16px #B4CEDD",
          borderRadius: "3%",
          opacity: 1,
          width: "97%",
          height: "97%",
          minHeight: "175px",
          minWidth: "175px",
        }}
      >
        {quizObject.length > 0 ? (
          <>
            <CardContent
              style={
                {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                } // Center text within the CardContent
              }
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <QuizIcon />
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{ color: "#B91414" }}
              >
                <b>Welcome to Cricket World Quiz Game</b>
              </Typography>
              <Typography
                sx={{ mt: 1.5 }}
                variant="h6"
                style={{ color: "black" }}
              >
                <b>Quiz Rules</b>
              </Typography>
            </CardContent>
            <CardContent sx={{ ml: 1 }}>
              {rules.map((item, index) => {
                return (
                  <Typography
                    sx={{ mb: 0.5 }}
                    variant="h6"
                    style={{ color: "black" }}
                    key={index}
                  >
                    <b>{index + 1}. </b>
                    {item}
                  </Typography>
                );
              })}
            </CardContent>
          </>
        ) : (
          <CardContent
            style={
              {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "100%",
              } // Ensure the CardContent takes up the full height // Center text within the CardContent
            }
          >
            <Typography
              variant="h5"
              component="div"
              style={{ color: "#B91414" }}
            >
              <b>NO ACTIVE QUIZ AVAILABLE</b>
            </Typography>
          </CardContent>
        )}
      </Card>
      {quizObject.length > 0 && (
        <Button
          variant="contained"
          color="error"
          size="large"
          sx={{ mt: 3 }}
          onClick={() => setQuiz(true)}
        >
          Start Quiz
        </Button>
      )}
    </Box>
  );
};

const QuizItem = (props) => {
  const {
    next,
    option,
    submitResponse,
    showPreviousQuiz,
    onAnswerSelected,
    selectedQuestionId,
    answerSelectionObject,
    setAnswerSelectionObject,
  } = props;

  const { id } = option;

  const isSelected = answerSelectionObject[selectedQuestionId].selected === id;
  let isCorrect =
    showPreviousQuiz &&
    answerSelectionObject[selectedQuestionId].correct === id;
  let isWrong =
    showPreviousQuiz &&
    isSelected &&
    answerSelectionObject[selectedQuestionId].selected !==
      answerSelectionObject[selectedQuestionId].correct;

  let backgroundColor = "#EEF8FE";
  if (isCorrect) {
    backgroundColor = "#53E3A0";
  } else if (isWrong) {
    backgroundColor = "#FF9494";
  } else if (isSelected && !isWrong) {
    backgroundColor = "#FFDD00";
  }

  return (
    <Button
      key={id}
      onClick={() => {
        !showPreviousQuiz &&
          !submitResponse &&
          onAnswerSelected(
            id,
            next,
            selectedQuestionId,
            answerSelectionObject,
            setAnswerSelectionObject
          );
      }}
      variant="contained"
      size="large"
      style={{
        width: "60%",
        backgroundColor: backgroundColor,
        color: "black",
      }}
      sx={{ mt: 2 }}
    >
      {/* <CheckIcon fontSize="medium" /> */}
      <Typography
        variant="h5"
        style={{ color: "black", fontWeight: "bold", margin: "2px" }}
      >
        {option.answer}
      </Typography>
    </Button>
  );
};

const QuizCard = (props) => {
  const {
    msisdn,
    winList,
    setQuiz,
    winModal,
    quizObject,
    quizResults,
    setWinModal,
    showWinModal,
    submitResponse,
    setShowWinModal,
    selectedAnswers,
    showPreviousQuiz,
    setSubmitResponse,
    setSelectedAnswers,
    setShowPreviousQuiz,
    answerSelectionObject,
    setAnswerSelectionObject,
  } = props;

  const {
    scrollRef,
    next,
    prev,
    goTo,
    pages,
    activePageIndex,
    snapPointIndexes,
    refresh,
  } = useSnapCarousel("x");

  useEffect(() => {
    goTo(0);
  }, [showPreviousQuiz]);

  const [showSubmit, setShowSubmit] = useState(
    submitResponse ? Boolean(submitResponse) : false
  );
  const [submitLoading, setSubmitLoading] = useState(false);

  const onAnswerSelected = (
    answerId,
    next,
    questionId,
    answerSelectionObject,
    setAnswerSelectionObject
  ) => {
    let tempAnserObj = answerSelectionObject;
    tempAnserObj[questionId].selected = answerId;

    setAnswerSelectionObject(tempAnserObj);

    if (selectedAnswers.some((obj) => obj.questionId === questionId)) {
      updateAnswer(answerId, questionId);
    } else {
      addAnswer(answerId, questionId);
    }

    if (activePageIndex < quizObject[0].questions.length - 1) {
      next();
    } else {
      setShowSubmit(true);
    }
  };

  const addAnswer = (answerId, questionId) => {
    const newObject = { questionId: questionId, answerId: answerId };
    setSelectedAnswers([...selectedAnswers, newObject]);
  };

  const updateAnswer = (answerId, questionId) => {
    const newArray = selectedAnswers.map((obj) => {
      if (obj.questionId === questionId) {
        return { ...obj, answerId: answerId };
      } else {
        return obj;
      }
    });

    setSelectedAnswers(newArray);
  };

  const onSubmit = async () => {
    let userMsisdn = msisdn;
    if (!msisdn || msisdn == null) {
      let user = JSON.parse(localStorage.getItem("user"));
      userMsisdn = user.phone;
    }

    try {
      const response = await axios.post(`${baseUrl}/api/v1/quiz/submitAnswer`, {
        quizId: quizObject[0].id,
        userId: userMsisdn,
        answers: selectedAnswers,
      });

      // Handle the response
      if (response) {
        setSubmitResponse(response.data);
      }
      setSubmitLoading(false);
    } catch (error) {
      // Handle errors
      setSubmitLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Box
      sx={{ mt: 1 }}
      style={
        {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#EEF8FE",
        } // You can adjust the height as needed
      }
    >
      {quizResults && (
        <PreviousQuizResult
          setQuiz={setQuiz}
          setShowWinModal={setShowWinModal}
          showPreviousQuiz={showPreviousQuiz}
          setShowPreviousQuiz={setShowPreviousQuiz}
        />
      )}
      <Typography
        sx={{ mt: 1.5 }}
        variant="h5"
        component="div"
        style={{ color: "black" }}
      >
        <b>Cricket World Quiz Game</b>
      </Typography>
      <Typography sx={{ mt: 2, mb: 3 }} variant="h6" style={{ color: "black" }}>
        Quiz - {new Date().toLocaleDateString()}
      </Typography>
      {quizObject ? (
        quizObject.map((item, index) => (
          <Card
            style={{
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 3px 16px #B4CEDD",
              borderRadius: "3%",
              width: "97%",
              height: "120%",
              opacity: 1,
            }}
          >
            <Carousel
              scrollRef={scrollRef}
              next={next}
              prev={prev}
              goTo={goTo}
              pages={pages}
              activePageIndex={activePageIndex}
              snapPointIndexes={snapPointIndexes}
              refresh={refresh}
              items={item.questions}
              renderItem={({ item: question, index: qIndex, isSnapPoint }) => (
                <CarouselItem
                  className="carousel-wrapper"
                  width="1000px"
                  key={question.id}
                  isSnapPoint={isSnapPoint}
                >
                  <CardContent>
                    <div className="mt-2">
                      <div key={"quiz" + qIndex}>
                        <Typography
                          variant="h6"
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          Question {qIndex + 1} of {item.questions.length}
                        </Typography>
                        <Typography
                          sx={{ mt: 1, mb: 2 }}
                          variant="h5"
                          style={{
                            color: "black",
                            marginBottom: "18%",
                            textAlign: "center",
                          }}
                        >
                          <b
                            style={{
                              lineHeight: "30px",
                            }}
                          >
                            {question.question}
                          </b>
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          {question.possibleAnswers.map(
                            (quizOptionItem, index) => (
                              <Box
                                style={
                                  {
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  } // You can adjust the height as needed
                                }
                              >
                                <QuizItem
                                  next={next}
                                  index={index}
                                  option={quizOptionItem}
                                  setShowSubmit={setShowSubmit}
                                  submitResponse={submitResponse}
                                  selectedQuestionId={question.id}
                                  showPreviousQuiz={showPreviousQuiz}
                                  onAnswerSelected={onAnswerSelected}
                                  length={question.possibleAnswers.length}
                                  setAnswerSelectionObject={
                                    setAnswerSelectionObject
                                  }
                                  answerSelectionObject={answerSelectionObject}
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      </div>
                    </div>
                  </CardContent>
                </CarouselItem>
              )}
            />
          </Card>
        ))
      ) : (
        <></>
      )}
      {!showPreviousQuiz & showSubmit ? (
        Boolean(submitResponse) ? (
          <p
            style={{
              marginTop: "8%",
              textAlign: "center",
              font: "normal normal 600 18px/24px Open Sans",
              color: "#000000",
            }}
          >
            Waiting for Result
          </p>
        ) : quizObject[0].quizStatus === "submitted" ? (
          <Button
            onClick={() => {}}
            variant="contained"
            color="warning"
            size="large"
            sx={{ mt: 3 }}
          >
            Quiz Time has expired!
          </Button>
        ) : (
          <Button
            onClick={() => {
              onSubmit();
              setSubmitLoading(true);
            }}
            variant="contained"
            color="error"
            size="large"
            sx={{ mt: 3 }}
          >
            {submitLoading ? "Loading..." : "Submit"}
          </Button>
        )
      ) : (
        <></>
      )}
      {showWinModal && (
        <Button
          variant="outlined"
          color="error"
          size="large"
          sx={{ mt: 3 }}
          onClick={() => setWinModal(true)}
        >
          View Winners
        </Button>
      )}
      <WinModal open={winModal} setOpen={setWinModal} winList={winList} />
      <Typography
        sx={{ mt: 4 }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ textAlign: "center" }}>
          <b>Note:</b> You can't modify your response once submitted. Before the
          start of match, the quiz will end. A user with three correct answers
          wins.
        </p>
      </Typography>
    </Box>
  );
};

const Quiz = (props) => {
  const [quiz, setQuiz] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [winModal, setWinModal] = useState(false);
  const [previousQuiz, setPreviousQuiz] = useState([]);
  const [showWinModal, setShowWinModal] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [previousResults, setPreviousResults] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showPreviousQuiz, setShowPreviousQuiz] = useState(false);
  const [answerSelectionObject, setAnswerSelectionObject] = useState([]);
  const [submittedAnswers, setSubmittedAnswers] = useState([]);

  const { msisdn } = props;

  const winList = {
    prize: "You Won 1GB of Jazz Internet data",
    winners: ["Salman Raza", "Shahid Hassan", "Naveed Salman"],
  };

  const rules = [
    "Submitted response can't be modified",
    "Quiz end before match starts",
    "Three correct answers qualifies for lucky draw",
    "Winner announced via lucky draw",
  ];

  useEffect(() => {
    let userMsisdn = msisdn;
    if (!msisdn || msisdn == null) {
      let user = JSON.parse(localStorage.getItem("user"));
      userMsisdn = user.phone;
    }
    const apiURL = `${baseUrl}/api/v1/quiz/allActive?userId=${userMsisdn}`;

    axios
      .get(apiURL)
      .then((response) => {
        setQuestions(response.data.data.activeQuiz);
        setSubmittedAnswers(
          response.data.data.userAnswers ? response.data.data.userAnswers : []
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getPreviousQuizResults = async () => {
    let userMsisdn = msisdn;
    if (!msisdn || msisdn == null) {
      let user = JSON.parse(localStorage.getItem("user"));
      userMsisdn = user.phone;
    }

    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/quiz/lastResult?userId=${userMsisdn}`
      );

      setPreviousResults(response.data.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    if (previousResults.quiz && previousResults.quiz.length > 0) {
      let previousQuizAnswers = {};

      for (let i = 0; i < previousResults.quiz[0].questions.length; i++) {
        let questionId = previousResults.quiz[0].questions[i].id;
        previousQuizAnswers[questionId] = {
          selected: -1,
          correct: -1,
        };
        for (
          let j = 0;
          j < previousResults.quiz[0].questions[i].possibleAnswers.length;
          j++
        ) {
          let answerItem =
            previousResults.quiz[0].questions[i].possibleAnswers[j];
          if (answerItem.isCorrect) {
            previousQuizAnswers[questionId].correct = answerItem.id;
          }
        }
      }

      for (let i = 0; i < previousResults.userAnswers.length; i++) {
        previousQuizAnswers[previousResults.quiz[0].questions[i].id].selected =
          previousResults.userAnswers[i].answerId;
      }

      let tempPreviousQuiz = {
        questions: previousResults.quiz,
        answers: previousQuizAnswers,
      };

      setPreviousQuiz(tempPreviousQuiz);
    }
  }, [previousResults]);

  useEffect(() => {
    getPreviousQuizResults();
  }, [msisdn]);

  useEffect(() => {
    if (questions) {
      questions.length &&
        setAnswerSelectionObject(
          questions[0].questions.reduce((acc, question) => {
            acc[question.id] = { selected: -1, correct: -1 };
            return acc;
          }, {})
        );

      if (submittedAnswers.length > 0) {
        let tempAnswerSelectionObject = answerSelectionObject;
        for (let i = 0; i < submittedAnswers.length; i++) {
          tempAnswerSelectionObject[submittedAnswers[i].questionId].selected =
            submittedAnswers[i].answerId;
        }
        setAnswerSelectionObject(tempAnswerSelectionObject);
      }
    }
  }, [questions, submittedAnswers]);

  return (
    <section
      id="main-section"
      className="px-lg-0 jc-inner-page  align-self-start"
    >
      {quiz && (questions.length > 0 || previousQuiz.questions.length > 0) ? (
        <QuizCard
          rules={rules}
          msisdn={msisdn}
          setQuiz={setQuiz}
          winList={winList}
          winModal={winModal}
          setWinModal={setWinModal}
          showWinModal={showWinModal}
          quizResults={previousResults}
          setShowWinModal={setShowWinModal}
          selectedAnswers={selectedAnswers}
          showPreviousQuiz={showPreviousQuiz}
          setSubmitResponse={setSubmitResponse}
          setSelectedAnswers={setSelectedAnswers}
          setShowPreviousQuiz={setShowPreviousQuiz}
          setAnswerSelectionObject={setAnswerSelectionObject}
          quizObject={showPreviousQuiz ? previousQuiz.questions : questions}
          answerSelectionObject={
            showPreviousQuiz ? previousQuiz.answers : answerSelectionObject
          }
          submitResponse={
            submittedAnswers.length > 0 ? submittedAnswers : submitResponse
          }
        />
      ) : (
        <WelcomeCard
          rules={rules}
          winList={winList}
          setQuiz={setQuiz}
          winModal={winModal}
          quizObject={questions}
          setWinModal={setWinModal}
          showWinModal={showWinModal}
          quizResults={previousResults}
          submitResponse={submitResponse}
          setShowWinModal={setShowWinModal}
          selectedAnswers={selectedAnswers}
          showPreviousQuiz={showPreviousQuiz}
          setSelectedAnswers={setSelectedAnswers}
          setShowPreviousQuiz={setShowPreviousQuiz}
          setAnswerSelectionObject={setAnswerSelectionObject}
        />
      )}
    </section>
  );
};

export default Quiz;
