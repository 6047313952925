import { useState, useCallback, useEffect, useMemo } from "react";
import { useIsomorphicLayoutEffect } from "./use-isomorphic-layout-effect";

export const useSnapCarousel = ({ axis = "x", initialPages = [] } = {}) => {
  const dimension = axis === "x" ? "width" : "height";
  const scrollDimension = axis === "x" ? "scrollWidth" : "scrollHeight";
  const clientDimension = axis === "x" ? "clientWidth" : "clientHeight";
  const nearSidePos = axis === "x" ? "left" : "top";
  const farSidePos = axis === "x" ? "right" : "bottom";
  const scrollPos = axis === "x" ? "scrollLeft" : "scrollTop";

  const [scrollEl, setScrollEl] = useState(null);
  const [{ pages, activePageIndex }, setCarouselState] = useState({
    pages: initialPages,
    activePageIndex: 0,
  });

  const refreshActivePage = useCallback(
    (pages) => {
      if (!scrollEl) {
        return;
      }
      const hasScrolledToEnd =
        Math.floor(scrollEl[scrollDimension] - scrollEl[scrollPos]) <=
        scrollEl[clientDimension];
      if (hasScrolledToEnd) {
        setCarouselState({ pages, activePageIndex: pages.length - 1 });
        return;
      }
      const items = Array.from(scrollEl.children);
      const scrollPort = scrollEl.getBoundingClientRect();
      const offsets = pages.map((page) => {
        const leadIndex = page[0];
        const leadEl = items[leadIndex];
        assert(leadEl instanceof HTMLElement, "Expected HTMLElement");
        const scrollSpacing = getEffectiveScrollSpacing(
          scrollEl,
          leadEl,
          nearSidePos
        );
        const rect = leadEl.getBoundingClientRect();
        const offset =
          rect[nearSidePos] - scrollPort[nearSidePos] - scrollSpacing;
        return Math.abs(offset);
      });
      const minOffset = Math.min(...offsets);
      const nextActivePageIndex = offsets.indexOf(minOffset);
      setCarouselState({ pages, activePageIndex: nextActivePageIndex });
    },
    [scrollEl, clientDimension, nearSidePos, scrollDimension, scrollPos]
  );

  const refresh = useCallback(() => {
    if (!scrollEl) {
      return;
    }
    const items = Array.from(scrollEl.children);
    const scrollPort = scrollEl.getBoundingClientRect();
    let currPageStartPos;
    const pages = items.reduce((acc, item, i) => {
      assert(item instanceof HTMLElement, "Expected HTMLElement");
      const currPage = acc[acc.length - 1];
      const rect = getOffsetRect(item, item.parentElement);
      if (
        !currPage ||
        rect[farSidePos] - currPageStartPos > Math.ceil(scrollPort[dimension])
      ) {
        acc.push([i]);
        const scrollSpacing = getEffectiveScrollSpacing(
          scrollEl,
          item,
          nearSidePos
        );
        currPageStartPos = rect[nearSidePos] - scrollSpacing;
      } else {
        currPage.push(i);
      }
      return acc;
    }, []);
    refreshActivePage(pages);
  }, [refreshActivePage, scrollEl, dimension, farSidePos, nearSidePos]);

  useIsomorphicLayoutEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    const handle = () => {
      refresh();
    };
    window.addEventListener("resize", handle);
    window.addEventListener("orientationchange", handle);
    return () => {
      window.removeEventListener("resize", handle);
      window.removeEventListener("orientationchange", handle);
    };
  }, [refresh]);

  useEffect(() => {
    if (!scrollEl) {
      return;
    }
    const handle = () => {
      refreshActivePage(pages);
    };
    scrollEl.addEventListener("scroll", handle);
    return () => {
      scrollEl.removeEventListener("scroll", handle);
    };
  }, [refreshActivePage, pages, scrollEl]);

  const handleGoTo = (index) => {
    if (!scrollEl) {
      return;
    }
    const page = pages[index];
    if (!page) {
      return;
    }
    const items = Array.from(scrollEl.children);
    const leadIndex = page[0];
    const leadEl = items[leadIndex];
    if (!(leadEl instanceof HTMLElement)) {
      return;
    }
    const scrollSpacing = getEffectiveScrollSpacing(
      scrollEl,
      leadEl,
      nearSidePos
    );
    scrollEl.scrollTo({
      behavior: "smooth",
      [nearSidePos]:
        getOffsetRect(leadEl, leadEl.parentElement)[nearSidePos] -
        scrollSpacing,
    });
  };

  const handlePrev = () => {
    handleGoTo(activePageIndex - 1);
  };

  const handleNext = () => {
    handleGoTo(activePageIndex + 1);
  };

  const snapPointIndexes = useMemo(
    () => new Set(pages.map((page) => page[0])),
    [pages]
  );

  return {
    prev: handlePrev,
    next: handleNext,
    goTo: handleGoTo,
    refresh,
    pages,
    activePageIndex,
    snapPointIndexes,
    scrollRef: setScrollEl,
  };
};

const getOffsetRect = (el, relativeTo) => {
  const rect = _getOffsetRect(el);
  if (!relativeTo) {
    return rect;
  }
  const relativeRect = _getOffsetRect(relativeTo);
  return {
    left: rect.left - relativeRect.left,
    top: rect.top - relativeRect.top,
    right: rect.right - relativeRect.left,
    bottom: rect.bottom - relativeRect.top,
    width: rect.width,
    height: rect.height,
  };
};

const _getOffsetRect = (el) => {
  const rect = el.getBoundingClientRect();
  let scrollLeft = 0;
  let scrollTop = 0;
  let parentEl = el.parentElement;
  while (parentEl) {
    scrollLeft += parentEl.scrollLeft;
    scrollTop += parentEl.scrollTop;
    parentEl = parentEl.parentElement;
  }
  const left = rect.left + scrollLeft;
  const top = rect.top + scrollTop;
  return {
    left,
    top,
    right: left + rect.width,
    bottom: top + rect.height,
    width: rect.width,
    height: rect.height,
  };
};

const getScrollPaddingUsedValue = (el, pos) => {
  const style = window.getComputedStyle(el);
  const scrollPadding = style.getPropertyValue(`scroll-padding-${pos}`);
  if (scrollPadding === "auto") {
    return 0;
  }
  const invalidMsg = `Unsupported scroll padding value, expected <length> or <percentage> value, received ${scrollPadding}`;
  if (scrollPadding.endsWith("px")) {
    const value = parseInt(scrollPadding);
    assert(!Number.isNaN(value), invalidMsg);
    return value;
  }
  if (scrollPadding.endsWith("%")) {
    const value = parseInt(scrollPadding);
    assert(!Number.isNaN(value), invalidMsg);
    return (el.clientWidth / 100) * value;
  }
  throw new RSCError(invalidMsg);
};

const getScrollMarginUsedValue = (el, pos) => {
  const style = window.getComputedStyle(el);
  const scrollMargin = style.getPropertyValue(`scroll-margin-${pos}`);
  const invalidMsg = `Unsupported scroll margin value, expected <length> value, received ${scrollMargin}`;
  assert(scrollMargin.endsWith("px"), invalidMsg);
  const value = parseInt(scrollMargin);
  assert(!Number.isNaN(value), invalidMsg);
  return value;
};

const getEffectiveScrollSpacing = (scrollEl, itemEl, pos) => {
  const scrollPadding = getScrollPaddingUsedValue(scrollEl, pos);
  const scrollMargin = getScrollMarginUsedValue(itemEl, pos);
  const rect = getOffsetRect(itemEl, itemEl.parentElement);
  return Math.min(scrollPadding + scrollMargin, rect[pos]);
};

function assert(value, message) {
  if (value) {
    return;
  }
  throw new RSCError(message);
}

class RSCError extends Error {
  constructor(message) {
    super(`[react-snap-carousel]: ${message}`);
  }
}
