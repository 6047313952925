import React from "react";

function WinnerBadge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46.273"
      height="42.865"
      viewBox="0 0 46.273 42.865"
    >
      <g transform="translate(0 -18.853)">
        <g data-name="Group 46787" transform="translate(0 18.853)">
          <path
            fill="#ffba57"
            d="M381.6 189.722l-2.8-1.163a1.468 1.468 0 01-.873-1.051l-.629-2.965a1.468 1.468 0 00-2.552-.65l-1.971 2.3a1.468 1.468 0 01-1.27.505l-.541-.057-1.327.588.584 6.69 7.125 2.8.96-.368.107-1.338a1.468 1.468 0 01.73-1.155l2.625-1.514a1.468 1.468 0 00-.168-2.622z"
            data-name="Path 103316"
            transform="translate(-336.231 -168.509)"
          />
          <path
            fill="#ffac3e"
            d="M331.258 217.013l2.141.226a2.7 2.7 0 00-.755 2.367l.693 4.039a.468.468 0 00.329.369 34.321 34.321 0 017.076 2.938l-.128 1.607-4.449.731-4.261-4.077-3.564-3.947z"
            data-name="Path 103317"
            transform="translate(-298.665 -199.104)"
          />
          <path
            fill="#ffba57"
            d="M.905 189.722l2.8-1.163a1.468 1.468 0 00.873-1.051l.629-2.965a1.468 1.468 0 012.552-.65l1.971 2.3a1.468 1.468 0 001.27.507l.541-.057 1.327.588-.584 6.69-7.125 2.8-.96-.368-.107-1.338a1.468 1.468 0 00-.73-1.155l-2.627-1.51a1.468 1.468 0 01.17-2.628z"
            data-name="Path 103318"
            transform="translate(0 -168.509)"
          />
          <path
            fill="#ffac3e"
            d="M55.92 217.013l-2.14.226a2.7 2.7 0 01.755 2.367l-.693 4.039a.468.468 0 01-.329.369 34.32 34.32 0 00-7.076 2.938l.128 1.607 4.449.731 4.261-4.077 3.564-3.947z"
            data-name="Path 103319"
            transform="translate(-42.24 -199.104)"
          />
          <path
            fill="#ffe27a"
            d="M97.628 36.126a2.7 2.7 0 01.776 2.389l-.812 4.735 1.5.9h16.95l1.5-.9-.812-4.735a2.7 2.7 0 01.776-2.389l3.99-3.889a2.7 2.7 0 00-1.5-4.6l-5.514-.8a2.7 2.7 0 01-2.033-1.477l-2.466-5a2.7 2.7 0 00-4.841 0l-2.466 5a2.7 2.7 0 01-2.032 1.477l-5.514.8a2.7 2.7 0 00-1.5 4.6z"
            data-name="Path 103320"
            transform="translate(-84.431 -18.853)"
          />
          <path
            fill="#f9cf58"
            d="M143.913 276.784l-.13.757a2.724 2.724 0 000 .954h20.2a2.722 2.722 0 000-.954l-.13-.757a42.233 42.233 0 00-19.95 0z"
            data-name="Path 103321"
            transform="translate(-130.752 -252.386)"
          />
          <path
            fill="#c61926"
            d="M392.323 394.377l-4.894.644.512 1.175-1.078.572-.7 2.152a.466.466 0 00.27.577 31.562 31.562 0 016.848 3.716.466.466 0 00.742-.372l.027-3.019a.466.466 0 01.248-.408l2.587-1.374a.466.466 0 00.064-.783 32.907 32.907 0 00-4.628-2.88z"
            data-name="Path 103322"
            transform="translate(-351.243 -360.438)"
          />
          <g data-name="Group 46783" transform="translate(35.619 34.583)">
            <g data-name="Group 46782">
              <g data-name="Group 46781">
                <path
                  fill="#b2182b"
                  d="M397.291 404.8a25.458 25.458 0 00-3.176-1.543l.567-1.747a22.59 22.59 0 013.79 1.886l-.518 1.188a.466.466 0 01-.663.216z"
                  data-name="Path 103323"
                  transform="translate(-394.115 -401.507)"
                />
              </g>
            </g>
          </g>
          <path
            fill="#c61926"
            d="M9.03 394.377l4.894.644-.512 1.175 1.078.572.7 2.152a.466.466 0 01-.27.577 31.562 31.562 0 00-6.848 3.716.466.466 0 01-.742-.372l-.03-3.02a.466.466 0 00-.248-.408l-2.586-1.373a.466.466 0 01-.064-.783 32.911 32.911 0 014.628-2.88z"
            data-name="Path 103324"
            transform="translate(-3.837 -360.438)"
          />
          <g data-name="Group 46786" transform="translate(6.298 34.583)">
            <g data-name="Group 46785">
              <g data-name="Group 46784">
                <path
                  fill="#b2182b"
                  d="M70.863 404.8a25.456 25.456 0 013.176-1.543l-.567-1.747a22.592 22.592 0 00-3.79 1.886l.518 1.188a.466.466 0 00.663.216z"
                  data-name="Path 103325"
                  transform="translate(-69.682 -401.507)"
                />
              </g>
            </g>
          </g>
          <path
            fill="#ff4755"
            d="M75.139 304.166s-5.594-3.578-15.934-3.578-15.969 3.748-15.969 3.748l-1.652-.37-1.893-4.338a.466.466 0 01.186-.586A38.716 38.716 0 0159.2 294.17a38.716 38.716 0 0119.328 4.872.466.466 0 01.186.586l-1.893 4.338z"
            data-name="Path 103326"
            transform="translate(-36.068 -269.288)"
          />
          <path
            fill="#fc2b3a"
            d="M78.652 352.864a34.788 34.788 0 00-17.621 4.491l.781 1.791s6.5-4.355 16.839-4.355 16.839 4.355 16.839 4.355l.781-1.791a34.788 34.788 0 00-17.621-4.491z"
            data-name="Path 103327"
            transform="translate(-55.515 -322.677)"
          />
        </g>
      </g>
    </svg>
  );
}

function QuizIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58.693"
      height="54.132"
      viewBox="0 0 58.693 54.132"
    >
      <g transform="translate(0 -19.893)">
        <path
          fill="#bbec6c"
          d="M29.409 238.962v22.012H2.863A2.862 2.862 0 010 258.111v-16.286a2.862 2.862 0 012.863-2.863h26.546z"
          data-name="Path 103329"
          transform="translate(0 -193.956)"
        />
        <path
          fill="#fe76a8"
          d="M285.83 241.826v16.286a2.862 2.862 0 01-2.863 2.863h-26.421v-22.012h26.421a2.862 2.862 0 012.863 2.863z"
          data-name="Path 103330"
          transform="translate(-227.137 -193.957)"
        />
        <path
          fill="#fe5694"
          d="M459.591 238.963h-3.553a2.862 2.862 0 012.863 2.863v16.286a2.862 2.862 0 01-2.863 2.863h3.553a2.862 2.862 0 002.863-2.863v-16.286a2.862 2.862 0 00-2.863-2.863z"
          data-name="Path 103331"
          transform="translate(-403.76 -193.957)"
        />
        <path
          fill="#fee97d"
          d="M165.962 124.578A13.392 13.392 0 10142.5 133.4a16.245 16.245 0 014.154 10.686v.039l5.291 2.57 6.549-2.57v-.041a15.542 15.542 0 013.979-10.488 13.337 13.337 0 003.493-9.017z"
          data-name="Path 103332"
          transform="translate(-123.223 -80.827)"
        />
        <path
          fill="#fee45a"
          d="M242.528 111.187a13.257 13.257 0 00-1.912.118 13.391 13.391 0 018.11 22.29 15.542 15.542 0 00-3.979 10.488v.041l1.295.886 2.258-.886v-.041a15.542 15.542 0 013.979-10.488 13.39 13.39 0 00-9.75-22.408z"
          data-name="Path 103333"
          transform="translate(-213.033 -80.828)"
        />
        <g data-name="Group 46791" transform="translate(25.01 53.423)">
          <path
            fill="#fff9e2"
            d="M220.993 323.147a.886.886 0 01-.865-.7l-1.936-8.988a.886.886 0 111.732-.373l1.936 8.988a.887.887 0 01-.867 1.072z"
            data-name="Path 103334"
            transform="translate(-218.172 -312.387)"
          />
        </g>
        <g data-name="Group 46792" transform="translate(29.975 53.423)">
          <path
            fill="#fff9e2"
            d="M262.371 323.147a.887.887 0 01-.867-1.072l1.936-8.988a.886.886 0 111.732.373l-1.936 8.988a.886.886 0 01-.865.699z"
            data-name="Path 103335"
            transform="translate(-261.484 -312.387)"
          />
        </g>
        <path
          fill="#7ca1b1"
          d="M216.2 401.343l-.694 1.1.694.676v.211a5.935 5.935 0 01-.268 1.768l-1.89.693.983 1.078a5.917 5.917 0 01-9.49 0l.594-.886-1.5-.886a5.938 5.938 0 01-.268-1.768v-.211l1.037-1.007-1.037-.764v-2.826h8.287z"
          data-name="Path 103336"
          transform="translate(-180.929 -335.22)"
        />
        <path
          fill="#678d98"
          d="M248.2 401.341l-.694 1.1.694.676v.211a5.935 5.935 0 01-.268 1.768l-1.89.693.983 1.078a5.925 5.925 0 01-6.521 2.109 5.924 5.924 0 004.144-5.649v-4.809h3.552v2.826z"
          data-name="Path 103337"
          transform="translate(-212.935 -335.218)"
        />
        <g data-name="Group 46794" transform="translate(21.592 38.584)">
          <path
            fill="#fff5c6"
            d="M195.359 192.918h-6.253a.751.751 0 01-.751-.751v-8.481a.751.751 0 01.751-.751h4.656a2.348 2.348 0 012.348 2.348l1.08 4.075-1.08 2.809a.751.751 0 01-.751.751z"
            data-name="Path 103338"
            transform="translate(-188.355 -182.935)"
          />
          <g
            fill="#fff9e2"
            data-name="Group 46793"
            transform="translate(7.755)"
          >
            <path
              d="M263 192.918h-6.253a.751.751 0 01-.751-.751v-6.884a2.348 2.348 0 012.348-2.348H263a.751.751 0 01.751.751v8.481a.751.751 0 01-.751.751z"
              data-name="Path 103339"
              transform="translate(-256.001 -182.935)"
            />
            <path
              d="M263 192.918h-6.253a.751.751 0 01-.751-.751v-6.884a2.348 2.348 0 012.348-2.348H263a.751.751 0 01.751.751v8.481a.751.751 0 01-.751.751z"
              data-name="Path 103340"
              transform="translate(-256.001 -182.935)"
            />
            <path
              d="M263 192.918h-6.253a.751.751 0 01-.751-.751v-6.884a2.348 2.348 0 012.348-2.348H263a.751.751 0 01.751.751v8.481a.751.751 0 01-.751.751z"
              data-name="Path 103341"
              transform="translate(-256.001 -182.935)"
            />
          </g>
        </g>
        <g data-name="Group 46795" transform="translate(4.179 52.101)">
          <path
            fill="#7ca1b1"
            d="M40.269 309.124a1.666 1.666 0 01-.814-.46l-2.535-2.535a1.594 1.594 0 012.255-2.255l1.2 1.2a.331.331 0 00.468 0l3.737-3.737a1.624 1.624 0 012.231-.085 1.594 1.594 0 01.056 2.308l-5.13 5.13a1.6 1.6 0 01-1.468.434z"
            data-name="Path 103342"
            transform="translate(-36.453 -300.853)"
          />
        </g>
        <path
          fill="#eef5f6"
          d="M387.753 301.943a.443.443 0 010-.626l1.551-1.551a1.594 1.594 0 00-2.255-2.255l-1.551 1.551a.443.443 0 01-.626 0l-1.519-1.519a1.624 1.624 0 00-2.231-.085 1.594 1.594 0 00-.056 2.308l1.551 1.551a.443.443 0 010 .626l-1.551 1.551a1.594 1.594 0 002.255 2.255l1.551-1.551a.443.443 0 01.626 0l1.551 1.551a1.594 1.594 0 002.255-2.255z"
          data-name="Path 103343"
          transform="translate(-336.97 -245.38)"
        />
        <g data-name="Group 46804" transform="translate(5.006 19.893)">
          <g transform="translate(0 14.975)">
            <g data-name="Group 46796">
              <path
                fill="#fff5c6"
                d="M46.772 153.266a.881.881 0 01-.355-.075l-2.219-.973a.886.886 0 11.712-1.622l2.219.973a.886.886 0 01-.356 1.7z"
                data-name="Path 103344"
                transform="translate(-43.667 -150.521)"
              />
            </g>
          </g>
          <g transform="translate(4.612 7.917)">
            <g data-name="Group 46797">
              <path
                fill="#fff9e2"
                d="M86.565 92.364a.882.882 0 01-.6-.234l-1.782-1.641a.886.886 0 111.2-1.3l1.782 1.641a.886.886 0 01-.6 1.537z"
                data-name="Path 103345"
                transform="translate(-83.897 -88.951)"
              />
            </g>
          </g>
          <g transform="translate(11.265 2.738)">
            <g data-name="Group 46798">
              <path
                fill="#fff5c6"
                d="M143.975 47.677a.885.885 0 01-.78-.464l-1.153-2.131a.886.886 0 111.558-.843l1.153 2.131a.886.886 0 01-.778 1.307z"
                data-name="Path 103346"
                transform="translate(-141.935 -43.775)"
              />
            </g>
          </g>
          <g transform="translate(19.239)">
            <g data-name="Group 46799">
              <path
                fill="#fff9e2"
                d="M212.783 24.055a.886.886 0 01-.872-.74l-.4-2.39a.886.886 0 011.747-.292l.4 2.39a.887.887 0 01-.875 1.032z"
                data-name="Path 103347"
                transform="translate(-211.499 -19.894)"
              />
            </g>
          </g>
          <g transform="translate(27.272)">
            <g data-name="Group 46800">
              <path
                fill="#fff5c6"
                d="M282.455 24.055a.887.887 0 01-.875-1.032l.4-2.39a.886.886 0 011.747.292l-.4 2.39a.886.886 0 01-.872.74z"
                data-name="Path 103348"
                transform="translate(-281.568 -19.893)"
              />
            </g>
          </g>
          <g transform="translate(34.492 2.738)">
            <g data-name="Group 46801">
              <path
                fill="#fff9e2"
                d="M345.435 47.676a.886.886 0 01-.778-1.307l1.153-2.131a.886.886 0 011.558.843l-1.153 2.131a.886.886 0 01-.78.464z"
                data-name="Path 103349"
                transform="translate(-344.55 -43.774)"
              />
            </g>
          </g>
          <g transform="translate(40.516 7.916)">
            <g data-name="Group 46802">
              <path
                fill="#fff5c6"
                d="M397.987 92.363a.886.886 0 01-.6-1.537l1.782-1.641a.886.886 0 111.2 1.3l-1.782 1.641a.883.883 0 01-.6.237z"
                data-name="Path 103350"
                transform="translate(-397.101 -88.951)"
              />
            </g>
          </g>
          <g transform="translate(44.691 14.975)">
            <g data-name="Group 46803">
              <path
                fill="#fff9e2"
                d="M434.408 153.267a.886.886 0 01-.356-1.7l2.219-.973a.886.886 0 01.712 1.622l-2.219.973a.886.886 0 01-.355.075z"
                data-name="Path 103351"
                transform="translate(-433.521 -150.523)"
              />
            </g>
          </g>
        </g>
        <g
          fill="#678d98"
          data-name="Group 46805"
          transform="translate(23.427 66.123)"
        >
          <path
            d="M212.645 423.167l.485.9-.485.876h-8.288v-1.771z"
            data-name="Path 103352"
            transform="translate(-204.357 -423.167)"
          />
          <path
            d="M214.449 455.885l.268.886-1.175.886H207.6a5.907 5.907 0 01-.907-1.771h7.752z"
            data-name="Path 103353"
            transform="translate(-206.429 -452.134)"
          />
        </g>
        <g
          fill="#598691"
          data-name="Group 46806"
          transform="translate(30.539 66.123)"
        >
          <path
            d="M267.312 455.886h3.552a5.908 5.908 0 01-.907 1.771H266.4a5.909 5.909 0 00.912-1.771z"
            data-name="Path 103354"
            transform="translate(-266.405 -452.135)"
          />
          <path
            d="M276.655 423.167h3.552v1.771h-3.552z"
            data-name="Path 103355"
            transform="translate(-275.48 -423.167)"
          />
        </g>
      </g>
    </svg>
  );
}

export { WinnerBadge, QuizIcon };
