import React, { Component } from "react";
import axios from "axios";

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Globals } from "../constants";
import {
  loginUser,
  tempUser,
  setCGObject,
  handleGoogleEvents,
  getSubscriptionAPIURL,
  validatePhone,
  internatonalizeNumber,
  setUserTelcoLogo,
  logoutUser,
  getTelcoFromOid,
  getTelcoName,
  canonicalLink,
  getCDNUrl,
} from "../HelperFunctions";

import "../../src/css/flags.css";
import EasyPaisaBundles from "../easypaisa/easypaisaBundles";
import Timer from "react-compound-timer/build";
import EasypaisaLoader from "../easypaisa/easypaisaLoaderModal";
import { Link } from "react-router-dom";
import AppTermsAndConditions from "../TermsAndConditions";
import SubscriptionPlans from "./SubscriptionPlanMobilink";

import { Helmet } from "react-helmet";
import PackageCard from "../newLoginFlow/packageCard";
import Loading from "../Loading";
import { withRouter } from "react-router-dom";
import InputMask from "react-input-mask";
import PayByOption from "./payByOption";

class LoginForCricwick extends Component {
  constructor(props) {
    super(props);

    this.Countries = [
      {
        name: "Select your country",
        cc: "",
        flag: getCDNUrl("./images/country.svg"),
      },
      {
        name: "Saudi Arabia",
        cc: "sa",
        placeholder: "966xxxxxxxxx",
        regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
        flag: getCDNUrl("./images/flags/ksa.jpg"),
      },
      {
        name: "Pakistan",
        cc: "pk",
        placeholder: "03xx xxxxxxx",
        regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
        flag: getCDNUrl("./images/flags/pak.jpg"),
      },
      {
        name: "Kuwait",
        cc: "kw",
        placeholder: "965 xxxxxxxx",
        regex: /^965\d{8}$|^05\d{8}$|^5\d{8}$|^9661111111\d{2}$/,
        flag: getCDNUrl("./images/flags/kwt.jpg"),
      },
      {
        name: "Oman",
        cc: "om",
        placeholder: "968 xxxxxxxx",
        regex: "",
        flag: getCDNUrl("./images/flags/oman_flag.jpg"),
      },
      {
        name: "Bahrain",
        cc: "bh",
        placeholder: "973 xxxxxxxx",
        regex: /^973\d{8}$/,
        flag: getCDNUrl("./images/flags/bhr.jpg"),
      },
      {
        name: "UAE",
        cc: "ae",
        placeholder: "971 xxxxxxx",
        regex: "",
        flag: getCDNUrl("./images/flags/uae.jpg"),
      },
      {
        name: "Srilanka",
        cc: "lk",
        placeholder: "94xxxxxxxxxx",
        regex: /^94\d{9}$/,
        flag: getCDNUrl("./images/flags/srilanka.jpg"),
      },

      // ,{ name: "Qatar", cc: 'qa' }
    ];

    this.state = {
      phone: "",
      pin: "",
      inputName: "phone",
      inputValue: this.props.msisdn ? this.props.msisdn : "",
      inputName1: "", //tariq
      inputValue1: "", //tariq
      telco: Globals.telco,
      secondServer: Globals.secondServer ? true : false,
      regex: Globals.regex,
      showPhone: Globals.telco ? "" : "d-none",
      phonePlaceholder: "Enter phone number", //Globals.login.phonePlaceholder,
      SubscriptionPackage: Globals.login.SubscriptionPackages ? "daily" : "",
      pinPlaceholder: Globals.login.pinPlaceholder,
      telcoMessageClass: "d-none",
      telcoMessage: "",
      telcoSubMessage: "",
      pinSent: false,
      disableTelcoSelect: "",
      task: "phone",
      user: [],
      redirect: false,
      btnTxt: "Subscribe",
      btnDisbaled: false,
      message: "",
      heading: Globals.login.enterNumber,
      enterPin: Globals.login.enterPin,
      strings: {
        somethingWentWrong: "Something went wrong. Please try again later!",
        invalidPin: "Invalid Pincode!",
        invalidPhone: Globals.login.invalidPhone,
      },
      selectedTelco: [{ name: "Choose operator", flag: "" }], //Tariq
      dropdownOpen: false, //Tariq
      countryDropDown: false, // Country Added Hamza
      selectedCountry: 0,
      isHE: false,
      confirmBtnText: "Confirm",
      confirmErrorMsg: "",
      selectedEPBundle: null,
      userTelcoName: "",
      userTelcoPricePoint: "",
      showInputFlow: false,
      gPayFlow: false,
      showPinFlow: false,
      showSubscriptionFlow: false,
      showLoginFlow: false,
      showNewSubscriptionFlow: false,
      showEPBundle: false,
      showResendButton: false,
      showTimer: true,
      showEPLoaderModal: false,
      showTermsandConditions: false,
      userTelcoPricePointSelected: null,
      plan: null,
      paymentGateway: -1,
      isLoading: true,
      lengthOfPhone: "13",
      prefill: null,
      lengthOfPin: "6",
      userInvited:
        localStorage.getItem("invitedUser") &&
        JSON.parse(localStorage.getItem("invitedUser")),
      fromLogin: true,
      cnicForJazzCash: "",
      payWithCard: true,
    };

    this.handleKeypress = this.handleKeypress.bind(this);
    this.login = this.login.bind(this);
    this.loginValuesChange = this.loginValuesChange.bind(this);
    this.subscribeWithTelco = this.subscribeWithTelco.bind(this);

    // this.apiPath = Globals.NodeApi + "api/";
    this.apiPath = Globals.NodeApi + "api/";
    this.textInput = React.createRef();
    this.isResendPinFlow = false;
    this.appStartAPIPath = `${Globals.UserManagment}api/v1/users/app-start`;

    //////////// Tariq
    this.toggle = this.toggle.bind(this);
    this.onEasyPaisaBundleSelected = this.onEasyPaisaBundleSelected.bind(this);
    this.toggleCountryDropDown = this.toggleCountryDropDown.bind(this);
    this.closeLoader = this.closeLoader.bind(this);

    if (localStorage.getItem("sub_source")) {
      this.sub_source = localStorage.getItem("sub_source");
    } else {
      this.sub_source = "";
    }

    localStorage.setItem("countryCode", "pk");
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async componentDidMount() {
    this.scrollToTop();

    try {
      let u =
        "https://api.ipfind.com/me?auth=67e9df22-44a6-4140-8885-c20966c7c1dd";
      let r = await fetch(u);
      let rp = await r.json();

      for (var i = 0; i < this.Countries.length; i++) {
        if (this.Countries[i].cc === rp.country_code.toLowerCase()) {
          this.setState({
            showLoginFlow: this.Countries[i].cc === "pk",
            selectedCountry: i,
            phonePlaceholder: this.Countries[i].placeholder,
            regex: this.Countries[i].regex,
            isLoading: false,
          });
        }
      }
    } catch (e) {
      this.setState({
        showLoginFlow: false,
        selectedCountry: 0,
        phonePlaceholder: this.Countries[0].placeholder,
        regex: this.Countries[0].regex,
        isLoading: false,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.msisdn) {
      this.setState({ inputValue: nextProps.msisdn });
    }
  }

  handleKeypress = function(e) {
    if (e.key === "Enter") {
      if (this.state.gPayFlow) {
        if (this.state.showPinFlow) this.confirmPinGPay();
        else this.sendPinGPay();
      } else {
        this.login();
      }
    }
  };

  async subscribeWithTelco() {
    let serverResponse = {};
    let phone = this.state.phone;
    if (!this.state.btnDisbaled) {
      this.setState(
        {
          btnTxt: "Please Wait ...",
          btnDisbaled: true,
          message: "",
          messageSentToLoader: "",
        },
        async () => {
          let url =
            this.apiPath +
            "send_pin?telco=" +
            this.state.userTelcoName +
            "&phone=" +
            this.state.phone +
            "&sub_type=" +
            `${this.state.userTelcoPricePointSelected.sub_type}` +
            "&source=2";
          url =
            this.sub_source.length > 0
              ? url + "&sub_source=" + this.sub_source
              : url +
                "&sub_source=" +
                `${
                  localStorage.getItem("domain")
                    ? localStorage.getItem("domain")
                    : Globals.telco
                      ? Globals.appName
                      : Globals.appName
                }`;
          url =
            url +
            "&app_name=" +
            `${
              localStorage.getItem("domain")
                ? localStorage.getItem("domain")
                : Globals.telco
                  ? Globals.appName
                  : Globals.appName
            }`;
          url = localStorage.getItem("source")
            ? url + "&source=" + localStorage.getItem("source")
            : url; // for jazzworld

          if (this.state.secondServer) {
            url += "&second_server=1";
          }

          serverResponse = await axios.get(url, {});
          serverResponse = serverResponse.data;

          // new check added for time being
          if (
            serverResponse.generic_response.message === "Invalid Phone number"
          ) {
            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "You have entered invalid mobile number",
            });
            // window.location.reload();
            return;
          }

          if (serverResponse) {
            localStorage.setItem(
              "isSocialProfileAttached",
              serverResponse.remote_response &&
              serverResponse.remote_response.user
                ? serverResponse.remote_response.user.is_social_profile_attached
                : "false"
            );

            this.setState({
              pinMessage:
                serverResponse.remote_response &&
                serverResponse.remote_response.resp &&
                serverResponse.remote_response.resp.disclaimer
                  ? serverResponse.remote_response.resp.disclaimer
                  : "",
              tncmessage:
                serverResponse.remote_response &&
                serverResponse.remote_response.resp &&
                serverResponse.remote_response.resp.tnc_text
                  ? serverResponse.remote_response.resp.tnc_text
                  : "",
            });

            let generic_response = {};

            if (
              (Object.keys(serverResponse).length === 0 &&
                serverResponse.constructor === Object) ||
              (!serverResponse.remote_response &&
                !serverResponse.generic_response)
            ) {
              //empty response, means no response from  node server
              generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
              // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
              // alert("Something went wrong, please check your network.");
            } else if (
              !serverResponse.status &&
              serverResponse.generic_response &&
              serverResponse.generic_response.call_type &&
              serverResponse.generic_response.call_type === "appstart" &&
              serverResponse.error
            ) {
              //appstart failed, remote server must not be responding
              generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
              // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
              // alert("Something went wrong, please check your internet connection.");
            } else if (!serverResponse.status) {
              //server responded but ERROR !
              this.setState({
                message: serverResponse.generic_response.message,
                btnTxt: "Subscribe",
                btnDisbaled: false,
              });
            } else {
              generic_response = serverResponse.generic_response;
            }
          }

          if (
            serverResponse.status &&
            serverResponse.generic_response.is_pin_sent
          ) {
            this.setFlowToRender("pinSent");
          }
        }
      );
    }
  }

  async subscribeWithEasyPaisa() {
    let serverResponse = {};
    let phone = this.state.phone;

    let url =
      this.apiPath +
      "send_pin?telco=easypaisa&phone=" +
      this.state.phone +
      "&sub_type=" +
      this.state.SubscriptionPackage +
      "&source=2";
    url =
      this.sub_source.length > 0
        ? url + "&sub_source=" + this.sub_source
        : url + "&sub_source=" + Globals.appName;

    if (!this.isResendPinFlow) {
      url = url + "&is_bundle=1&bundle_id=" + this.state.selectedEPBundle.id;
    }
    url = localStorage.getItem("source")
      ? url + "&source=" + localStorage.getItem("source")
      : url; // for jazzworld

    if (this.state.secondServer) {
      url += "&second_server=1";
    }

    serverResponse = await axios.get(url, {});
    serverResponse = serverResponse.data;

    // new check added for time being
    if (serverResponse.generic_response.message === "Invalid Phone number") {
      this.setState({
        btnTxt: "Subscribe",
        btnDisbaled: false,
        message: "You have entered invalid mobile number",
      });
      // window.location.reload();
      return;
    }

    if (serverResponse) {
      localStorage.setItem(
        "isSocialProfileAttached",
        serverResponse.remote_response && serverResponse.remote_response.user
          ? serverResponse.remote_response.user.is_social_profile_attached
          : "false"
      );

      this.isResendPinFlow = false;

      this.setState({
        pinMessage:
          serverResponse.remote_response &&
          serverResponse.remote_response.resp &&
          serverResponse.remote_response.resp.disclaimer
            ? serverResponse.remote_response.resp.disclaimer
            : "",
        tncmessage:
          serverResponse.remote_response &&
          serverResponse.remote_response.resp &&
          serverResponse.remote_response.resp.tnc_text
            ? serverResponse.remote_response.resp.tnc_text
            : "",
      });
      let generic_response = {};

      if (!serverResponse.remote_response.status) {
        this.setState({
          btnTxt: "CONTINUE",
          btnDisbaled: false,
          message: serverResponse.remote_response.status_message,
        });
        // window.location.reload();
        return;
      }

      if (
        (Object.keys(serverResponse).length === 0 &&
          serverResponse.constructor === Object) ||
        (!serverResponse.remote_response && !serverResponse.generic_response)
      ) {
        //empty response, means no response from  node server
        generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
        // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
        // alert("Something went wrong, please check your network.");
      } else if (
        !serverResponse.status &&
        serverResponse.generic_response &&
        serverResponse.generic_response.call_type &&
        serverResponse.generic_response.call_type === "appstart" &&
        serverResponse.error
      ) {
        //appstart failed, remote server must not be responding
        generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
        // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
        // alert("Something went wrong, please check your internet connection.");
      } else if (!serverResponse.status) {
        //server responded but ERROR !
        this.setState({
          message: serverResponse.generic_response.message,
          btnTxt: "Subscribe",
          btnDisbaled: false,
        });
      } else {
        generic_response = serverResponse.generic_response;
      }
    }

    if (serverResponse.status && serverResponse.generic_response.is_pin_sent) {
      this.state.userTelcoName = "easypaisa";
      this.setFlowToRender("pinSent");
    }
  }

  sendPinGPay = async () => {
    let url = `${Globals.UserManagment}api/v1/otp/sendOtp`;

    this.setState(
      {
        btnTxt: "Please Wait ...",
        btnDisbaled: true,
        message: "",
        messageSentToLoader: "",
      },
      async () => {
        axios
          .post(url, {
            phoneNumber: this.state.phone,
            appName: localStorage.getItem("domain")
              ? localStorage.getItem("domain")
              : Globals.telco
                ? Globals.appName
                : "",
          })
          .then((response) => {
            if (response.data.status === 1) {
              this.setFlowToRender("pinSent");
            }
            // uncomment the below code to show errors
            // else if (response.data.status === 0) {
            //   this.setState({
            //     btnTxt: "Continue",
            //     btnDisbaled: false,
            //     message: response.data.message[0],
            //     messageSentToLoader: response.data.message[0],
            //   });
            // }
          })
          .catch((error) => {
            this.setState({
              btnTxt: "Continue",
              btnDisbaled: false,
              message: error.message,
              messageSentToLoader: error.message,
            });
          });
      }
    );
  };

  confirmPinGPay = async () => {
    this.setState(
      {
        btnTxt: "Please Wait ...",
        btnDisbaled: true,
        message: "",
        messageSentToLoader: "",
      },
      async () => {
        let url = `${Globals.UserManagment}api/v1/otp/verifyOtp`;

        axios
          .post(url, {
            phoneNumber: this.state.phone,
            otpCode: this.state.pin,
            appName: localStorage.getItem("domain")
              ? localStorage.getItem("domain")
              : Globals.telco
                ? Globals.appName
                : "",
            platform: "web",
          })
          .then((response) => {
            if (response.status === 200) {
              let xAccessToken = response.headers.authtoken;
              let data = response.data.data;
              if (
                data.subscriptionStatus === 1 ||
                data.subscriptionStatus === 2
              ) {
                let user = {
                  name: data.name,
                  isSocialLogin: data.isSocialLogin,
                  email: data.email,
                  phone: data.msisdn,
                  subscribed:
                    data.subscriptionStatus === 1 ||
                    data.subscriptionStatus === 2
                      ? 1
                      : 0,
                  signInProvider: data.signInProvider,
                  telco: getTelcoName(data.telcoId, 0),
                  profilePicture: data.avatarUrl,
                  cnic: data.cnic,
                  cnicIssueDate: data.cnicIssueDate,
                  isSocialProfileAttached: data.isSocialProfileAttached,
                  xAccessToken: xAccessToken,
                };

                localStorage.setItem("user_logged_in", true);
                loginUser(user);
                window.location = document.documentURI;
              } else {
                logoutUser();
              }
            }
          })
          .catch((error) => {
            this.setState({
              btnTxt: "Continue",
              btnDisbaled: false,
              message: error.message,
              messageSentToLoader: error.message,
            });
          });
      }
    );
  };

  subscribeWithJazzCash = async () => {
    let serverResponse = {};

    if (!this.state.cnicForJazzCash.length) {
      this.setState({
        btnTxt: "Send Pin",
        btnDisbaled: false,
        message: "You have entered invalid CNIC",
      });
      return;
    }

    let url = `${Globals.NodeApi}api/one_time_payment?telco=jazzcash&phone=${
      this.state.phone
    }&bundle_id=${this.state.plan.id}&source=2&sub_source=${
      this.sub_source.length > 0
        ? this.sub_source
        : localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
    }&last_six_cnic=${this.state.cnicForJazzCash
      .replace(/-/g, "")
      .slice(-6)}&app_name=${
      localStorage.getItem("domain")
        ? localStorage.getItem("domain")
        : Globals.telco
          ? Globals.appName
          : Globals.appName
    }`;

    try {
      serverResponse = await axios.get(url, {});
      serverResponse = serverResponse.data;

      // new check added for time being
      if (
        serverResponse.status === 0 &&
        serverResponse.message === "Invalid Phone number"
      ) {
        this.setState({
          btnTxt: "Subscribe",
          btnDisbaled: false,
          message: "You have entered invalid mobile number",
        });
        // window.location.reload();
        return;
      }

      if (serverResponse.status === 0) {
        this.setState({
          btnTxt: "Subscribe",
          btnDisbaled: false,
          message: serverResponse.status_message
            ? serverResponse.status_message
            : serverResponse.message,
        });
        // window.location.reload();
        return;
      }
      if (
        serverResponse &&
        serverResponse.status === 1 &&
        serverResponse.remote_response &&
        serverResponse.remote_response.status === 0
      ) {
        this.setState({
          btnTxt: "Subscribe",
          btnDisbaled: false,
          message: serverResponse.remote_response.status_message
            ? serverResponse.remote_response.status_message
            : serverResponse.message,
        });
        // window.location.reload();
        return;
      }

      if (
        serverResponse &&
        serverResponse.status === 1 &&
        serverResponse.remote_response &&
        serverResponse.remote_response.status === 1 &&
        serverResponse.remote_response.user
      ) {
        let user = serverResponse.remote_response.user;
        localStorage.setItem(
          "isSocialProfileAttached",
          user ? user.is_social_profile_attached : "false"
        );
        let xAccessToken = user ? user.x_access_token : "";
        if (xAccessToken) {
          axios
            .post(this.appStartAPIPath, {
              token: xAccessToken,
              tokenType: "telco",
              appName: localStorage.getItem("domain")
                ? localStorage.getItem("domain")
                : Globals.telco
                  ? Globals.appName
                  : "",
              platform: "web",
            })
            .then((resp) => {
              let data = resp.data.data;
              if (
                data.subscriptionStatus === 1 ||
                data.subscriptionStatus === 2
              ) {
                let user = {
                  name: data.name,
                  isSocialLogin: data.isSocialLogin,
                  email: data.email,
                  phone: data.msisdn,
                  subscribed:
                    data.subscriptionStatus === 1 ||
                    data.subscriptionStatus === 2
                      ? 1
                      : 0,
                  signInProvider: data.signInProvider,
                  telco: getTelcoName(data.telcoId, 0),
                  profilePicture: data.avatarUrl,
                  cnic: data.cnic,
                  cnicIssueDate: data.cnicIssueDate,
                  isSocialProfileAttached: data.isSocialProfileAttached,
                  xAccessToken: xAccessToken,
                  isUserGracePeriodElapsed: data.isUserGracePeriodElapsed,
                  subscriptionType: data.subscriptionType,
                  paymentType: data.paymentType,
                  subscriptionStatus: data.subscriptionStatus,
                };
                localStorage.setItem("user_logged_in", true);
                loginUser(user);
                window.location = document.documentURI;
              } else {
                logoutUser();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } catch (error) {
      console.log(`serverResponse,`, serverResponse);
      this.setState({
        btnDisbaled: false,
        message: "Something went wrong",
      });
    }
  };

  subscribeWithJazzTelco = async (phone) => {
    let sub_source = this.sub_source;
    let url = "send_pin?telco=mobilink&phone=" + phone + "&sub_type=1&source=2";
    url =
      sub_source.length > 0
        ? url + "&sub_source=" + sub_source
        : url + "&sub_source=" + Globals.appName;

    try {
      let subscriptionStatusURL = this.apiPath + url;
      subscriptionStatusURL = localStorage.getItem("source")
        ? subscriptionStatusURL + "&source=" + localStorage.getItem("source")
        : subscriptionStatusURL; // for jazzworld

      if (this.state.secondServer) {
        subscriptionStatusURL += "&second_server=1";
      }

      let serverResponse = await axios.get(subscriptionStatusURL, {});
      serverResponse = serverResponse.data;

      if (serverResponse.generic_response.message === "Invalid Phone number") {
        this.setState({
          btnTxt: "CONTINUE",
          btnDisbaled: false,
          message: "You have entered invalid mobile number",
        });
        // window.location.reload();
        return;
      }

      if (serverResponse) {
        localStorage.setItem(
          "isSocialProfileAttached",
          serverResponse.remote_response && serverResponse.remote_response.user
            ? serverResponse.remote_response.user.is_social_profile_attached
            : "false"
        );

        this.setState({
          pinMessage:
            serverResponse.remote_response &&
            serverResponse.remote_response.resp &&
            serverResponse.remote_response.resp.disclaimer
              ? serverResponse.remote_response.resp.disclaimer
              : "",
          tncmessage:
            serverResponse.remote_response &&
            serverResponse.remote_response.resp &&
            serverResponse.remote_response.resp.tnc_text
              ? serverResponse.remote_response.resp.tnc_text
              : "",
          userTelcoName:
            serverResponse.remote_response &&
            serverResponse.remote_response.resp
              ? getTelcoName(
                  serverResponse.remote_response.resp.telco_id,
                  serverResponse.remote_response.resp.subscribed_by_id
                )
              : "",
        });

        let generic_response = {};

        if (
          (Object.keys(serverResponse).length === 0 &&
            serverResponse.constructor === Object) ||
          (!serverResponse.remote_response && !serverResponse.generic_response)
        ) {
          //empty response, means no response from  node server
          generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
          // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
          // alert("Something went wrong, please check your network.");
        } else if (
          !serverResponse.status &&
          serverResponse.generic_response &&
          serverResponse.generic_response.call_type &&
          serverResponse.generic_response.call_type === "appstart" &&
          serverResponse.error
        ) {
          //appstart failed, remote server must not be responding
          generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
          // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
          // alert("Something went wrong, please check your internet connection.");
        } else if (!serverResponse.status) {
          //server responded but ERROR !
          this.setState({
            message: serverResponse.generic_response.message,
            btnTxt: "Continue",
            btnDisbaled: false,
          });
        } else {
          generic_response = serverResponse.generic_response;
        }
      }

      if (
        serverResponse.status &&
        serverResponse.generic_response.is_pin_sent
      ) {
        this.setFlowToRender("pinSent");
      }

      // -----------------------------------------------------------------
    } catch (err) {
      console.log("Error logging in", err);
    }
  };

  payWithJazzCard = async () => {
    let url = `${Globals.NodeApi}api/card_payment?telco=card_payment&phone=${
      this.state.phone
    }&bundle_id=${this.state.plan.id}&source=2&sub_source=${
      this.sub_source.length > 0
        ? this.sub_source
        : localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
    }&app_name=${
      localStorage.getItem("domain")
        ? localStorage.getItem("domain")
        : Globals.telco
          ? Globals.appName
          : Globals.appName
    }`;

    let serverResponse;
    try {
      serverResponse = await axios.get(url, {});
      // console.log("Server response for card", serverResponse);
      serverResponse = serverResponse.data;

      if (serverResponse.status === 0) {
        this.setState({
          btnTxt: "Pay now",
          btnDisbaled: false,
          message: serverResponse.status_message
            ? serverResponse.status_message
            : serverResponse.message,
        });
        return;
      }
      if (serverResponse.remote_response.status === 0) {
        this.setState({
          btnTxt: "Pay now",
          btnDisbaled: false,
          message: serverResponse.remote_response.status_message
            ? serverResponse.remote_response.status_message
            : serverResponse.remote_response.message,
        });
        return;
      }

      if (
        serverResponse &&
        serverResponse.status === 1 &&
        serverResponse.remote_response &&
        serverResponse.remote_response.status === 1 &&
        serverResponse.remote_response.user
      ) {
        window.open(serverResponse.remote_response.redirect_url, "_self");
      }
    } catch (error) {
      console.log(`serverResponse,`, serverResponse);
      this.setState({
        btnDisbaled: false,
        message: "Something went wrong",
      });
    }
  };

  async login() {
    let phone = internatonalizeNumber(
      this.Countries[this.state.selectedCountry].cc,
      this.state.telco,
      this.state.phone
    );
    //  ;
    phone = phone.replace(/ /g, "");
    this.setState({ phone: phone }); // set phone state after internationalization

    if (this.state.selectedCountry !== 2 && this.state.selectedCountry > 0) {
      this.loginFlowForMENA();
      return;
    }

    let serverResponse = {};
    let subscriptionResponse = {};

    this.setState(
      {
        btnTxt: "Please Wait ...",
        btnDisbaled: true,
        message: "",
        messageSentToLoader: "",
      },
      async () => {
        if (this.state.showInputFlow || this.state.fromLogin) {
          //appstart or send pin

          let telco = this.state.telco;
          let regex = this.state.regex;

          const result = validatePhone(phone, regex);

          if (this.state.selectedCountry === 0) {
            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "Please select your country",
            });
            return false;
          }

          if (!result || !phone) {
            //Fail Event Sent to Google for loging.
            handleGoogleEvents(
              "Login",
              `Login-${this.state.telco}`,
              `Phone-Invalid`
            );

            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "You have entered invalid mobile number",
            });
            return false;
          }

          try {
            let subscriptionStatusURL =
              this.apiPath +
              getSubscriptionAPIURL(
                Globals.telco,
                phone,
                this.sub_source,
                this.state.userInvited
              );

            subscriptionResponse = await axios.get(subscriptionStatusURL, {});

            if (
              subscriptionResponse.data &&
              subscriptionResponse.data.status === 1
            ) {
              subscriptionResponse = subscriptionResponse.data.remote_response;
              this.setState({
                userTelcoLogo: setUserTelcoLogo(
                  subscriptionResponse.telco_name
                ),
                userTelcoName:
                  subscriptionResponse.subscribed_by_name &&
                  subscriptionResponse.subscribed_by_name === "easypaisa"
                    ? "easypaisa"
                    : subscriptionResponse.telco_name,
                // userTelcoPricePoint: subscriptionResponse.price_point,
                // userTelcoPricePoints: subscriptionResponse.price_points,
                isUserAlreadySubscribed:
                  subscriptionResponse.subscribed_by_name &&
                  subscriptionResponse.subscribed_by_name === "easypaisa"
                    ? true
                    : false,
                userOrginalTelco: subscriptionResponse.telco_name,
                pinMessage:
                  subscriptionResponse.resp &&
                  subscriptionResponse.resp.disclaimer
                    ? subscriptionResponse.resp.disclaimer
                    : "",
                tncmessage:
                  subscriptionResponse.resp &&
                  subscriptionResponse.resp.tnc_text
                    ? subscriptionResponse.resp.tnc_text
                    : "",
              });
            }

            if (subscriptionResponse.is_subscribed) {
              if (subscriptionResponse.is_pin_sent) {
                handleGoogleEvents(
                  "Login",
                  `Login-${this.state.telco}`,
                  `Pin-Send-Sucessful`
                );

                this.setState(
                  {
                    userTelcoPricePointSelected: {
                      sub_type: subscriptionResponse.user.subscription_type,
                    },
                  },
                  () => this.setFlowToRender("pinSent")
                );

                return;
              } else {
                console.log("Something went worng");
              }
            } else if (
              subscriptionResponse.status &&
              !subscriptionResponse.is_subscribed
            ) {
              if (this.state.fromLogin) {
                this.setState({
                  fromLogin: false,
                  showLoginFlow: false,
                  showNewSubscriptionFlow: true,
                  btnTxt: "Subscribe",
                  btnDisbaled: false,
                  message: "",
                });
              } else {
                if (!this.state.plan) {
                  // this.setState(
                  //   {
                  //     btnDisbaled: false,
                  //   },
                  //   () => this.setFlowToRender("subscription")
                  // );
                  this.subscribeWithJazzTelco(phone);
                }
                if (
                  this.state.plan.payment_gateways[this.state.paymentGateway]
                    .title === "JazzCash"
                ) {
                  if (this.state.payWithCard) this.payWithJazzCard();
                  // call here jazz payment with card
                  else this.subscribeWithJazzCash();
                } else if (
                  this.state.plan.payment_gateways[this.state.paymentGateway]
                    .title === "EasyPaisa"
                ) {
                  this.setState(
                    {
                      selectedEPBundle: {
                        id: this.state.plan.id,
                      },
                    },
                    () => this.subscribeWithEasyPaisa()
                  );
                }
              }
            } else if (!subscriptionResponse.status) {
              this.setState({
                btnDisbaled: false,
                message: subscriptionResponse.status_message.length
                  ? subscriptionResponse.status_message
                  : "Sorry but something went wrong.",
                btnTxt: "Subscribe",
              });
            }
          } catch (err) {
            console.log("Error logging in", err);
          }
        } else {
          //pin is sent so call confirm pin start

          let confrimPinResponse = {};
          const pin = this.state.pin;
          try {
            // here change the url path on the basis of this.state.gPayFlow
            let url = `${this.apiPath}confirmpin?telco=${
              this.state.userTelcoName
            }&phone=${phone}&pin=${pin}&sub_type=${
              this.state.userTelcoPricePointSelected &&
              this.state.userTelcoPricePointSelected.sub_type
                ? this.state.userTelcoPricePointSelected.sub_type
                : ""
            }&app_name=${
              localStorage.getItem("domain")
                ? localStorage.getItem("domain")
                : Globals.telco
                  ? Globals.appName
                  : Globals.appName
            }`;

            url = localStorage.getItem("source")
              ? url + "&source=" + localStorage.getItem("source")
              : url; // for jazzworld

            if (this.state.secondServer) {
              url += "&second_server=1";
            }

            confrimPinResponse = await axios.get(url, {}).then((response) => {
              if (response.status === 200) {
                // this.setState({
                //     showEPLoaderModal: false
                // });

                confrimPinResponse = response.data ? response.data : null;

                if (confrimPinResponse) {
                  let generic_response = {};
                  // localStorage.setItem(
                  //   "isSocialProfileAttached",
                  //   confrimPinResponse.remote_response &&
                  //   confrimPinResponse.remote_response.user
                  //     ? confrimPinResponse.remote_response.user
                  //         .is_social_profile_attached
                  //     : "false"
                  // );

                  if (
                    Object.keys(confrimPinResponse).length === 0 &&
                    confrimPinResponse.constructor === Object
                  ) {
                    // no server response, ERROR ! login with a temporary user
                    // generic_response = tempUser(phone);
                    this.setState({
                      message: "Something went wrong!",
                      btnTxt: "Confirm",
                      btnDisbaled: false,
                      messageSentToLoader: "Something went wrong!",
                    });
                  } else if (!confrimPinResponse.status) {
                    this.setState({
                      message: confrimPinResponse.generic_response.message,
                      btnTxt: "Confirm",
                      btnDisbaled: false,
                      messageSentToLoader:
                        confrimPinResponse.generic_response.message,
                    });
                  } else {
                    generic_response = confrimPinResponse.generic_response;
                  }

                  let xAccessToken =
                    confrimPinResponse.remote_response &&
                    confrimPinResponse.remote_response.user
                      ? confrimPinResponse.remote_response.user.x_access_token
                      : "";

                  if (generic_response.subscribed) {
                    this.setState({ message: "" });
                    // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });

                    generic_response.phone = phone;
                    generic_response.telco =
                      this.state.userTelcoName === "easypaisa"
                        ? this.state.userOrginalTelco
                        : this.state.userTelcoName;
                    generic_response.isEasypaisaSubscription =
                      this.state.userTelcoName === "easypaisa" ? true : false;

                    generic_response.xAccessToken = xAccessToken;

                    localStorage.setItem("user_logged_in", true);
                    localStorage.setItem(
                      "user",
                      JSON.stringify(generic_response)
                    );

                    handleGoogleEvents(
                      "Login",
                      `Login-${this.state.telco}`,
                      `Pin-Confirm-Ok-Subscribed`
                    );

                    loginUser(generic_response);

                    window.location = document.documentURI;
                  }
                }
              }
            });
          } catch (err) {
            handleGoogleEvents(
              "Login",
              `Login-${this.state.telco}`,
              `Pin-Confirm-Pin-Error`
            );
            console.log("Error confirming pin", err);
          }
        }
      }
    );
  }

  sendPinAgain() {
    this.isResendPinFlow = true;
    this.setState({
      showResendButton: false,
      showTimer: true,
    });
    if (
      this.state.plan &&
      this.state.plan.payment_gateways[this.state.paymentGateway].title ===
        "GPay"
    )
      this.sendPinGPay();
    else if (
      this.state.selectedCountry == 2 &&
      this.state.userTelcoName !== "easypaisa"
    ) {
      this.subscribeWithTelco();
    } else if (
      this.state.selectedCountry == 2 &&
      this.state.userTelcoName === "easypaisa"
    ) {
      this.subscribeWithEasyPaisa();
    }
  }

  loginFlowForMENA() {
    let phone = this.state.phone.replace(/ /g, "");

    let serverResponse = {};
    this.setState(
      {
        btnTxt: "Please Wait ...",
        btnDisbaled: true,
        message: "",
      },
      async () => {
        if (this.state.showInputFlow) {
          //appstart or send pin
          let telco = this.state.telco;
          let regex = this.state.regex;

          const result = validatePhone(phone, regex);

          if (this.state.selectedCountry === 0) {
            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "Please select your country",
            });
            return false;
          }

          if (!this.state.telco) {
            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "Please select your operator",
            });
            return false;
          }

          if (
            this.state.selectedTelco.name === "Easypaisa" &&
            !this.state.selectedEPBundle
          ) {
            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "Please select your Easypaisa bundle",
            });
            return false;
          }

          if (!result || !phone) {
            //Fail Event Sent to Google for loging.
            handleGoogleEvents(
              "Login",
              `Login-${this.state.telco}`,
              `Phone-Invalid`
            );

            this.setState({
              btnTxt: "Subscribe",
              btnDisbaled: false,
              message: "You have entered invalid mobile number",
            });
            return false;
          }

          try {
            if (telco === "dialog_sl") {
              // just using it for dialog_sl
              // first check if it is already subscribed so just login
              let status = 0;
              let API =
                "https://cors-anywhere.herokuapp.com/" +
                `http://api.cricboom.com/userapi/appstart?web_user=1&phone=${phone}`;
              let response = await fetch(API);
              let json = await response.json();

              if (
                json.user.subscribe_status === 1 ||
                json.user.subscribe_status === 3
              ) {
                status = 1;
              }

              if (status === 1) {
                let generic_response = {};

                this.setState({ message: "" });

                generic_response.phone = phone;
                generic_response.telco = this.state.telco;

                localStorage.setItem("user_logged_in", true);
                localStorage.setItem("user", JSON.stringify(generic_response));

                loginUser(generic_response);
                handleGoogleEvents(
                  "Login",
                  `Login-${this.state.telco}`,
                  `User-Logged_In`
                );

                window.location = document.documentURI;
              } else {
                try {
                  API =
                    "https://cors-anywhere.herokuapp.com/" +
                    `http://v3.sdpksa.com/api/Apigate/getAOCToken`;
                  response = await fetch(API, {
                    method: "POST",
                    body: JSON.stringify({
                      serviceId: "1",
                      servicePassword: "Khaleef007",
                      isMobileAppAPI: "0",
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                    },
                  });
                } catch (e) {
                  console.log("Error", e);
                }

                json = await response.json();

                if (json.aocToken) {
                  window.location.href = `http://msisdn.mife-aoc.com/api/aoc?aocToken=${
                    json.aocToken
                  }`;
                } else {
                  window.location = document.documentURI;
                }
              }

              return;
            }

            if (telco === "zain_kw") {
              let url =
                this.apiPath +
                "appstart?web_user=1&telco=zain_kw&phone=" +
                phone;
              serverResponse = await axios.get(url, {});
              serverResponse = serverResponse.data;

              if (
                serverResponse.generic_response &&
                (serverResponse.generic_response.subscribed === 0 ||
                  serverResponse.generic_response.subscribed === 2)
              ) {
                this.setState({ btnTxt: "Please Wait ..." });

                let telco = this.state.telco.telco,
                  referer = window.location.href;

                setCGObject(telco, referer);

                let zain_cgURL =
                  "http://msisdn.sla-alacrity.com/purchase?merchant=partner:0207649cc19c60742e10059aadc3610ee31ed2b9&service=campaign:961f938c7b485da401b0966e3e4960ee7238f884&correlator=1_106_xyz&locale=en&redirect_url=" +
                  window.location.origin +
                  "/authentication_zain/";

                window.location.href = zain_cgURL;

                return;
              } else {
                // user is subscribed with status 1 or 3
                let generic_response = serverResponse.generic_response;

                this.setState({ message: "" });
                // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });

                generic_response.phone = phone;
                generic_response.telco = this.state.telco;
                localStorage.setItem("user_logged_in", true);
                localStorage.setItem("user", JSON.stringify(generic_response));

                handleGoogleEvents(
                  "Login",
                  `Login-${this.state.telco}`,
                  `User-Logged_In`
                ); //

                loginUser(generic_response);
                //window.history.back();
                window.location = document.documentURI;
                return false;
              }
            }

            let url =
              this.apiPath +
              "login_or_sendPin?telco=" +
              this.state.telco +
              "&phone=" +
              phone +
              "&sub_type=" +
              `${
                this.state.userTelcoPricePointSelected.sub_type
                  ? this.state.userTelcoPricePointSelected.sub_type
                  : ""
              }`;
            url = localStorage.getItem("source")
              ? url + "&source=" + localStorage.getItem("source")
              : url; // for jazzworld

            if (this.state.secondServer) {
              url += "&second_server=1";
            }

            serverResponse = await axios.get(url, {});
            serverResponse = serverResponse.data;

            // new check added for time being
            if (
              serverResponse.generic_response.message === "Invalid Phone number"
            ) {
              this.setState({
                btnTxt: "Subscribe",
                btnDisbaled: false,
                message: "You have entered invalid mobile number",
              });
              // window.location.reload();
              return;
            }
          } catch (err) {
            console.log("Error logging in", err);
          }

          if (serverResponse) {
            localStorage.setItem(
              "isSocialProfileAttached",
              serverResponse.remote_response &&
              serverResponse.remote_response.user
                ? serverResponse.remote_response.user.is_social_profile_attached
                : "false"
            );

            let generic_response = {};

            if (
              (Object.keys(serverResponse).length === 0 &&
                serverResponse.constructor === Object) ||
              (!serverResponse.remote_response &&
                !serverResponse.generic_response)
            ) {
              //empty response, means no response from  node server
              generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
              // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
              // alert("Something went wrong, please check your network.");
            } else if (
              !serverResponse.status &&
              serverResponse.generic_response &&
              serverResponse.generic_response.call_type &&
              serverResponse.generic_response.call_type === "appstart" &&
              serverResponse.error
            ) {
              //appstart failed, remote server must not be responding
              generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
              // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
              // alert("Something went wrong, please check your internet connection.");
            } else if (!serverResponse.status) {
              //server responded but ERROR !
              this.setState({
                message: serverResponse.generic_response.message,
                btnTxt: "Subscribe",
                btnDisbaled: false,
              });
            } else {
              generic_response = serverResponse.generic_response;
            }

            if (generic_response.subscribed) {
              this.setState({ message: "" });
              // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });

              generic_response.phone = phone;
              generic_response.telco = this.state.telco;
              localStorage.setItem("user_logged_in", true);
              localStorage.setItem("user", JSON.stringify(generic_response));

              handleGoogleEvents(
                "Login",
                `Login-${this.state.telco}`,
                `User-Logged_In`
              );

              if (this.state.telco === "zain") {
                // zain KSA login Hamza
                loginUser(generic_response);
                window.location.href = `http://cric.sa.zain.com?q=${phone}`;
                return;
              }

              // Zunair - Above If condition is commented out to stop Zain telco users to be redirected to cric.sa.zain.com
              // Zunair - This will let them stay on cricwick.net
              // Zunair - This is done beacuase we are streaming IPL matches on cricwick.net
              // Zunair - But only for Saudia Arabian users.

              loginUser(generic_response);
              //window.history.back();
              window.location = document.documentURI;
              return false;
            }
          }

          if (
            serverResponse.status &&
            !serverResponse.generic_response.subscribed
          ) {
            //pin sent
            this.setFlowToRender("pinSent");
          }
        } else if (this.state.showPinFlow) {
          const pin = this.state.pin;
          try {
            let url = `${this.apiPath}confirmpin?telco=${
              this.state.telco
            }&phone=${phone}&pin=${pin}&sub_type=${
              this.state.userTelcoPricePointSelected &&
              this.state.userTelcoPricePointSelected.sub_type
                ? this.state.userTelcoPricePointSelected.sub_type
                : ""
            }`;
            url = localStorage.getItem("source")
              ? url + "&source=" + localStorage.getItem("source")
              : url; // for jazzworld
            if (this.state.secondServer) {
              url += "&second_server=1";
            }
            serverResponse = await axios.get(url, {
              // withCredentials: true,
              // mode: 'cors',
              // headers: {
              //     'Access-Control-Allow-Credentials': true,
              //     'Access-Control-Allow-Origin': true
              // }
            });
            serverResponse = serverResponse.data;
          } catch (err) {
            handleGoogleEvents(
              "Login",
              `Login-${this.state.telco}`,
              `Pin-Confirm-Pin-Error`
            );
            console.log("Error confirming pin", err);
          }

          if (serverResponse) {
            let generic_response = {};
            if (
              Object.keys(serverResponse).length === 0 &&
              serverResponse.constructor === Object
            ) {
              // no server response, ERROR ! login with a temporary user
              //  generic_response = tempUser(phone);
              this.setState({
                message: "Something went wrong!",
                btnTxt: "Confirm",
                btnDisbaled: false,
              });
            } else if (!serverResponse.status) {
              this.setState({
                message: serverResponse.generic_response.message,
                btnTxt: "Confirm",
                btnDisbaled: false,
              });
            } else {
              generic_response = serverResponse.generic_response;
            }

            if (generic_response.subscribed) {
              this.setState({ message: "" });
              // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
              generic_response.phone = phone;
              generic_response.telco = this.state.telco;
              localStorage.setItem("user_logged_in", true);
              localStorage.setItem("user", JSON.stringify(generic_response));

              handleGoogleEvents(
                "Login",
                `Login-${this.state.telco}`,
                `Pin-Confirm-Ok-Subscribed`
              );

              // if (this.state.telco === 'zain') {
              //     // zain KSA login Hamza
              //     window.location.href = `http://cric.sa.zain.com?q=${phone}`;
              //     return;
              // }

              // Zunair - Above If condition is commented out to stop Zain telco users to be redirected to cric.sa.zain.com
              // Zunair - This will let them stay on cricwick.net
              // Zunair - This is done beacuase we are streaming IPL matches on cricwick.net
              // Zunair - But only for Saudia Arabian users.

              loginUser(generic_response);
              // window.history.back();
              window.location = document.documentURI;
            }
          }
        }
      }
    );
  }

  loginValuesChange = (event) => {
    let lastKeyStroke = event.target.value[event.target.value.length - 1];
    let value = event.target.value;
    let setState = {};
    if (
      event.target.name === this.state.inputName &&
      this.state.prefill &&
      value.length < this.state.prefill.length
    ) {
      setState = {
        [event.target.name]: this.state.prefill,
        inputValue: this.state.prefill,
      };
    } else {
      setState = {
        [event.target.name]:
          (value.length && isNaN(lastKeyStroke)) || lastKeyStroke === " "
            ? this.state[event.target.name]
            : value,
        inputValue: value,
      };
    }
    this.setState(setState);
  };

  setCountry(value) {
    let selectedCountry = this.Countries[value];

    this.setState({
      selectedCountry: value,
      selectedTelco: [{ name: "Choose operator", flag: "" }],
      showPhone: "d-none",
      telco: null,
      phonePlaceholder: selectedCountry.placeholder,
      regex: selectedCountry.regex,
      inputValue: "",
      showLoginFlow: this.Countries[value].cc === "pk",
      // showNewSubscriptionFlow: this.Countries[value].cc === "pk",
      showInputFlow: this.Countries[value].cc !== "pk",
      lengthOfPhone: "13",
      [this.state.inputName]: "",
      prefill: null,
      lengthOfPin: "6",
    });

    localStorage.setItem("countryCode", this.Countries[value].cc);
  }

  setTelco = async (val) => {
    let setState = {
      telco: "",
      showPhone: "d-none",
      telcoMessageClass: "d-none",
      telcoMessage: "",
      telcoSubMessage: "",
      selectedTelco: { name: "", flag: "" },
      phonePlaceholder: "",
      lengthOfPhone: "13",
      prefill: null,
      lengthOfPin: "6",
    };

    /******  Header Enrichment ********/
    let API;
    let Response;
    let Data = null;
    if (Data && Data.MSISDN_status === 1) {
      setState.inputValue = Data.MSISDN;
    }

    /******  Header Enrichment ********/

    this.setState(setState);

    if (val) {
      setState.telco = val.telco;
      const _telco = Globals.telcos.filter((x) => x.telco === val.telco);

      if (!val.consentGatewayRedirection) {
        setState.showPhone = "";
        setState.heading = val.login.enterNumber;
        setState.enterPin = val.login.enterPin;
        setState.phonePlaceholder = val.login.phonePlaceholder;
        setState.pinPlaceholder = val.login.pinPlaceholder;
        setState.invalidPhone = val.login.invalidPhone;
        setState.regex = _telco[0].regex;
        setState.selectedTelco.name = val.name;
        setState.selectedTelco.flag = val.flag;
        if (val.login.prefill) {
          setState[this.state.inputName] = val.login.prefill;
          setState.prefill = val.login.prefill;
        }
        if (val.login.pinLength) {
          setState.lengthOfPin = val.login.pinLength;
        }

        if (val.login.numberLength) {
          setState.lengthOfPhone = `${val.login.numberLength}`;
        }

        if (val.telco_message) {
          setState.telcoMessageClass = "";
          setState.telcoMessage = val.telco_message;
        }

        if (val.telco_subMessage) {
          setState.telcoSubMessage = val.telco_subMessage;
        }

        this.setState(setState);
        this.textInput.current.focus();
      } else {
        //redirect to CG
        this.setState({ btnTxt: "Please Wait ..." });
        let telco = val.telco,
          referer = window.location.href;
        setCGObject(telco, referer);
        window.open(val.cgUrl);

        return;
      }
    }
  };

  setSubscriptionPackage = (e) => {
    e = e.target.value;
    let val = e ? e : "";
    this.setState({ SubscriptionPackage: val });
  };

  toggleCountryDropDown() {
    this.setState((prevState) => ({
      countryDropDown: !prevState.countryDropDown,
      telcoMessage: "",
      telcoSubMessage: "",
    }));
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  closeLoader() {
    this.setState({ showEPLoaderModal: false });
  }

  onEasyPaisaBundleSelected(selectedBundle) {
    if (selectedBundle) {
      this.setState({
        selectedEPBundle: selectedBundle,
      });
    }
  }

  getPinFlowStates() {
    let pinFlowState = {
      phonePlaceholder: this.state.phonePlaceholder,

      pinSent: true,
      message: "",
      btnTxt: "Confirm",
      btnDisbaled: false,
      showInputFlow: false,
      showPinFlow: true,
      showSubscriptionFlow: false,
      showLoginFlow: false,
      showEPBundle: false,
      pin: "",
      showTermsandConditions: false,
      fromLogin: false,
    };
    return pinFlowState;
  }

  setFlowToRender(flow) {
    let setThisState = {};
    if (flow === "phone" || flow === "resendPin") {
      setThisState = {
        btnDisbaled: false,
        btnTxt: "Subscribe",
        showInputFlow: true,
        showPinFlow: false,
        showSubscriptionFlow: false,
        showEPBundle: false,
        pin: "",
        userTelcoName: "",
        isUserAlreadySubscribed: false,
        selectedEPBundle: null,
        pinMessage: "",
        tncmessage: "",
        isResendPinFlow: false,
        showTermsandConditions: false,
        message: "",
      };
    } else if (flow === "pinSent") {
      setThisState = this.getPinFlowStates();
    } else if (flow === "subscription") {
      setThisState = {
        showInputFlow: false,
        showPinFlow: false,
        showSubscriptionFlow: true,
        showEPBundle: false,
        showTermsandConditions: false,
        pin: "",
      };
    } else if (flow === "easypaisa") {
      setThisState = {
        btnDisbaled: false,
        btnTxt: "Subscribe",
        showInputFlow: false,
        showPinFlow: false,
        showSubscriptionFlow: false,
        showEPBundle: true,
        showTermsandConditions: false,
        pin: "",
        userTelcoName: "easypaisa",
      };
    } else if (flow === "tnc") {
      setThisState = {
        btnDisbaled: false,
        btnTxt: "Subscribe",
        showInputFlow: false,
        showPinFlow: false,
        showSubscriptionFlow: false,
        showEPBundle: false,
        showTermsandConditions: true,
        pin: "",
      };
    }

    this.setState(setThisState);
  }

  renderCountryList = (telcos) => {
    return (
      <div className="row">
        <div className="col-12">
          <Dropdown
            isOpen={this.state.countryDropDown}
            toggle={this.toggleCountryDropDown}
          >
            <DropdownToggle
              color="default"
              style={{
                width: 100 + "%",
                textAlign: "left",
                backgroundColor: "white",
                borderBottomColor: "lightgray",
                paddingLeft: "10px",
                paddingBottom: 8 + "px",
                borderRadius: 0,
                height: "65px",
                border: "none",
                //fontWeight: 600,
                background: "#F8F7F7",
              }}
            >
              <span>
                <img
                  src={this.Countries[this.state.selectedCountry].flag}
                  alt=""
                  width="22px"
                  //style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }}
                  style={{ paddingBottom: "5px" }}
                />
              </span>
              <span
                style={{
                  fontSize: "1.7rem",
                  paddingLeft: 10 + "px",
                  textTransform: "capitalize",
                }}
              >
                {this.Countries[this.state.selectedCountry].name}
              </span>
              <i
                className="fa fa-angle-down"
                style={{ float: "right", fontSize: "18px" }}
              />
            </DropdownToggle>

            <DropdownMenu style={{ width: 100 + "%", borderRadius: 0 }}>
              {this.Countries.map(
                (telco, i) =>
                  i !== 0 && (
                    <DropdownItem
                      key={i}
                      value={telco.name}
                      onClick={() => this.setCountry(i)}
                    >
                      <span>
                        <img
                          src={telco.flag}
                          alt=""
                          width="22px"
                          //style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }}
                        />
                      </span>

                      <span
                        style={{ fontSize: "1.6rem", paddingLeft: 10 + "px" }}
                      >
                        {telco.name}
                      </span>
                    </DropdownItem>
                  )
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  };

  renderPhoneInput = () => {
    return (
      <div>
        {this.state.plan &&
        this.state.plan.payment_gateways[this.state.paymentGateway].title ===
          "JazzCash" &&
        !this.state.payWithCard ? (
          <div>
            <div
              className="mb-1"
              style={{ fontSize: "1.7rem", fontWeight: "600" }}
            >
              {`Enter Your CNIC`}
            </div>
            <InputMask
              className="payout-input-cnic"
              mask="99999-9999999-9"
              value={this.state.cnicForJazzCash}
              name="cnicForJazzCash"
              id="cnicForJazzCash"
              placeholder="xxxxx-xxxxxxx-x"
              // onChange={this.loginValuesChange}
              onChange={(e) =>
                this.setState({
                  cnicForJazzCash: e.target.value,
                })
              }
            />
          </div>
        ) : null}

        <div className="mb-1" style={{ fontSize: "1.7rem", fontWeight: "600" }}>
          {this.state.heading}
        </div>
        <input
          name={this.state.inputName}
          type="text"
          ref={this.textInput}
          placeholder={this.state.phonePlaceholder}
          value={this.state.phone}
          onChange={this.loginValuesChange}
          onKeyPress={this.handleKeypress}
          autoComplete="off"
          className="telco-input"
          maxLength={this.state.lengthOfPhone}
        />
        <p
          className="message mt-3 text-danger text-center"
          style={{ fontSize: "16px" }}
        >
          {this.state.message}
        </p>
      </div>
    );
  };

  renderLoginFlowNew = () => {
    return (
      <div>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div>
            <img
              src={getCDNUrl(`/images/trivia/back-btn.png`)}
              width={16}
              height={16}
              alt={`back button`}
              style={{
                cursor: `pointer`,
              }}
              onClick={() =>
                this.setState({
                  showNewSubscriptionFlow: true,
                  showInputFlow: false,
                  plan: null,
                  paymentGateway: -1,
                  gPayFlow: false,
                })
              }
            />
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.7rem",
              fontWeight: "600",
            }}
          >
            Subscriptions
          </div>
          <div />
        </div>
        <br />

        {this.state.plan ? (
          <div>
            <div
              style={{
                textAlign: "start",
                marginBottom: "20px",
                fontSize: "1.7rem",
                lineHeight: "1.2 !important",
                fontWeight: "600",
              }}
            >
              Your plan:
            </div>

            <div className={`mt-3`}>
              <PackageCard
                packageDetails={this.state.plan}
                dontShowPaymentGateways
                expanded
              />
            </div>
            <div
              className={`mt-3 d-flex justify-content-center align-items-center`}
            >
              <img
                src={getCDNUrl(
                  `/images/payout/${
                    this.state.plan.payment_gateways[this.state.paymentGateway]
                      .title
                  }.png`
                )}
                alt=""
                width={`30%`}
                height={`auto`}
              />
            </div>
            {this.state.plan &&
            this.state.plan.payment_gateways[this.state.paymentGateway]
              .title === "JazzCash" ? (
              <div
                className={`mt-3 d-flex flex-column flex-md-row justify-content-center align-items-center`}
              >
                <PayByOption
                  option={{ title: "Wallet" }}
                  selected={!this.state.payWithCard}
                  onCardClick={() =>
                    this.setState({
                      payWithCard: false,
                    })
                  }
                  parentClasses={"mr-2"}
                />
                <PayByOption
                  option={{ title: "Card" }}
                  selected={this.state.payWithCard}
                  onCardClick={() =>
                    this.setState({
                      payWithCard: true,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={`mt-4`}>
          {this.renderPhoneInput()}

          <input type="hidden" name="flow" value={this.state.task} />
          {Globals.login.SubscriptionPackages && !this.state.pinSent ? (
            <div className="radio">
              <label className="radio-inline">
                <input
                  type="radio"
                  value="daily"
                  name="radioB"
                  defaultChecked
                  onChange={this.setSubscriptionPackage}
                  style={{ paddingLeft: "10px" }}
                  autoComplete="off"
                />{" "}
                Daily{" "}
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  value="monthly"
                  name="radioB"
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Monthly{" "}
              </label>
            </div>
          ) : (
            ""
          )}

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-5 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={() => {
              if (this.state.gPayFlow) this.sendPinGPay();
              else this.login();
            }}
            disabled={this.state.btnDisbaled}
          >
            {this.state.plan
              ? this.state.payWithCard
                ? `Pay Now`
                : `Send Pin`
              : this.state.btnTxt}
          </button>

          {!this.state.gPayFlow ? (
            !Globals.telco && Globals.telcos ? (
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                style={{ margin: "15px auto 0 auto" }}
              >
                {/* Remove this check when you add new telco for oman*/}
                {this.state.selectedCountry !== 4
                  ? this.state.telcoMessage
                  : "OMR 1.25 /week"}
              </p>
            ) : (
              <p
                className={"text-center w-50"}
                style={{ margin: "15px auto 0 auto" }}
              >
                {Globals.login.price_points ? Globals.login.price_points : ""}
              </p>
            )
          ) : null}
        </div>
      </div>
    );
  };

  renderLoginFlow = () => {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "1.7rem",
            lineHeight: "1.2 !important",
            fontWeight: "600",
          }}
        >
          Subscribe now to watch Premium Cricket Content and Play Fantasy League
          for free to Win Exciting Prizes in every match.
        </div>
        <br />

        {this.state.plan ? (
          <div>
            <div>
              <PackageCard
                packageDetails={this.state.plan}
                dontShowPaymentGateways
                expanded
              />
            </div>
            <div
              className={`mt-3 d-flex justify-content-center align-items-center`}
            >
              <img
                src={getCDNUrl(
                  `/images/payout/${
                    this.state.plan.payment_gateways[this.state.paymentGateway]
                      .title
                  }.png`
                )}
                alt=""
                width={`50%`}
                height={`auto`}
              />
            </div>
          </div>
        ) : null}

        <div className={`mt-3`}>
          {this.state.gPayFlow ? null : this.renderCountryList(Globals.telcos)}
          <br />
          {!this.state.gPayFlow &&
          this.state.selectedCountry !== 2 &&
          Globals.telco === ""
            ? this.renderTelcosList(Globals.telcos)
            : ""}
          <br />
          {this.renderPhoneInput()}

          <input type="hidden" name="flow" value={this.state.task} />
          {Globals.login.SubscriptionPackages && !this.state.pinSent ? (
            <div className="radio">
              <label className="radio-inline">
                <input
                  type="radio"
                  value="daily"
                  name="radioB"
                  defaultChecked
                  onChange={this.setSubscriptionPackage}
                  style={{ paddingLeft: "10px" }}
                  autoComplete="off"
                />{" "}
                Daily{" "}
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  value="monthly"
                  name="radioB"
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Monthly{" "}
              </label>
            </div>
          ) : (
            ""
          )}

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-5 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={() => {
              if (this.state.gPayFlow) this.sendPinGPay();
              else this.login();
            }}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>

          {!this.state.gPayFlow ? (
            !Globals.telco && Globals.telcos ? (
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                style={{ margin: "15px auto 0 auto" }}
              >
                {/* Remove this check when you add new telco for oman*/}
                {this.state.selectedCountry !== 4
                  ? this.state.telcoMessage
                  : "OMR 1.25 /week"}
              </p>
            ) : (
              <p
                className={"text-center w-50"}
                style={{ margin: "15px auto 0 auto" }}
              >
                {Globals.login.price_points ? Globals.login.price_points : ""}
              </p>
            )
          ) : null}
          {!Globals.telco &&
          Globals.telcos &&
          this.state.telcoSubMessage.length > 0 ? (
            <p
              className={"text-center w-50 font-weight-bold"}
              style={{ margin: "0 auto 0 auto" }}
            >
              {this.state.telcoSubMessage}
            </p>
          ) : null}

          {!Globals.telco && Globals.telcos ? (
            <p
              className={"text-center w-50" + this.state.telcoMessageClass}
              style={{
                margin: `${
                  this.state.telcoSubMessage.length > 0 ? "30" : "15"
                }px auto 0 auto`,
              }}
            >
              {this.state.telcoMessage}
            </p>
          ) : (
            <p
              className={"text-center w-50"}
              style={{ margin: "15px auto 0 auto" }}
            >
              {Globals.login.price_points ? Globals.login.price_points : ""}
            </p>
          )}
        </div>
      </div>
    );
  };

  renderTelcosList = (telcos) => {
    for (var i = 0; i < this.Countries.length; i++) {
      for (var j = 0; j < telcos.length; j++) {
        if (telcos[j]["countryCode"] == this.Countries[i]["cc"]) {
          this.Countries[i]["flag"] = telcos[j]["flag"];
        }
      }
    }

    let SelectedTelcos = [];
    let CurrentFlag = this.Countries[0]["flag"];

    Globals.telcos.forEach((telco, i) => {
      if (this.Countries[this.state.selectedCountry].cc === telco.countryCode) {
        CurrentFlag = telco.flag;
        SelectedTelcos.push(telco);
      }
    });

    return (
      <div>
        {/* This drop down holds telcos*/}
        {/* <label className="login-label">Select your operator and enter number</label> */}
        <div className="row">
          <div className="col-12">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle
                color="default"
                style={{
                  width: 100 + "%",
                  textAlign: "left",
                  backgroundColor: "white",
                  borderBottomColor: "lightgray",
                  paddingLeft: "10px",
                  paddingBottom: 8 + "px",
                  borderRadius: 0,
                  height: "65px",
                  border: "none",
                  //fontWeight: 600,
                  background: "#F8F7F7",
                }}
              >
                <span>
                  <img
                    src={getCDNUrl("/images/operator.svg")}
                    alt=""
                    width="22px"
                    style={{ paddingBottom: "5px" }}
                    //style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }}
                  />
                </span>

                <span
                  style={{
                    fontSize: "1.6rem",
                    paddingLeft: 10 + "px",
                    textTransform: "capitalize",
                    color: this.state.selectedTelco.name ? "" : "#737373",
                  }}
                >
                  {this.state.selectedTelco.name
                    ? this.state.selectedTelco.name
                    : "Operator"}
                </span>

                <i
                  className="fa fa-angle-down"
                  style={{ float: "right", fontSize: "18px" }}
                />
              </DropdownToggle>

              <DropdownMenu style={{ width: 100 + "%", borderRadius: 0 }}>
                {SelectedTelcos.map((telco, i) => (
                  <DropdownItem
                    key={i}
                    value={telco.name}
                    onClick={() => this.setTelco(telco)}
                    //    onChange={()  => this.setTelco(telco)}
                  >
                    {/* <span >
                                            <img src={telco.flag} alt='' style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }} />
                                        </span> */}

                    <span
                      style={{ fontSize: "1.6rem", paddingLeft: 10 + "px" }}
                    >
                      {telco.name}
                    </span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  displayTermsandCondtions() {
    this.setState({
      showTermsandConditions: true,
    });
  }

  renderTermsandConditions = () => {
    return <AppTermsAndConditions operatorName={this.state.userTelcoName} />;
  };

  renderTelcosForPin = () => {
    return (
      //Hide, no need in new design
      <Dropdown
        style={{ display: "none" }}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle
          color="default"
          style={{
            width: 100 + "%",
            textAlign: "left",
            backgroundColor: "white",
            borderBottomColor: "lightgray",
            paddingLeft: "10px",
            borderRadius: 0,
            background: "#F8F7F7",
          }}
        >
          <span>
            <img
              src={this.state.selectedTelco.flag}
              alt=""
              style={{ boxShadow: "0px  1px  1px  0px gray", borderRadius: 3 }}
            />
          </span>

          <span
            style={{
              fontSize: 1.2 + "em",
              paddingLeft: 10 + "px",
              textTransform: "capitalize",
            }}
          >
            {this.state.selectedTelco.name}
          </span>

          {/* <span style={{ float: 'right' }}>
                        <i className="fas fa-chevron-down"></i>
                    </span> */}
        </DropdownToggle>
      </Dropdown>
    );
  };

  renderLogin = () => {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "1.7rem",
            lineHeight: "1.2 !important",
            fontWeight: "600",
          }}
        >
          LOGIN TO CONTINUE
        </div>
        <br />

        <div>
          {this.renderPhoneInput()}

          <input type="hidden" name="flow" value={this.state.task} />
          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-5 p-3 mb-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.login}
            disabled={this.state.btnDisbaled}
          >
            {`Login`}
          </button>
          <div
            className="mb-1 text-center"
            style={{ fontSize: "1.9rem", fontWeight: "800" }}
          >
            {`OR`}
          </div>
          <div
            className="mt-5 redCl text-center"
            style={{
              fontSize: "1.7rem",
              fontWeight: "600",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              this.setState({
                showLoginFlow: false,
                fromLogin: false,
                showNewSubscriptionFlow: true,
              });
            }}
          >
            {`SUBSCRIBE NOW`}
          </div>
        </div>
      </div>
    );
  };

  renderPin = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Pin Verification{" "}
        </div>
        <h3 className="text-center mb-3">
          We have sent PIN code via SMS to following number
        </h3>
        <h3 className="text-center mb-5">
          {" "}
          {this.state.phone}{" "}
          <span
            onClick={() => this.setFlowToRender("resendPin")}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <img src={getCDNUrl("/images/edit.svg")} alt="" />
          </span>{" "}
        </h3>
        {/* <div>{ this.renderTelcosForPin()}</div> */}

        <div className="">
          {this.state.plan && (
            <>
              <h4>Your Plan:</h4>
              <div>
                <PackageCard
                  packageDetails={this.state.plan}
                  dontShowPaymentGateways
                  expanded
                />
              </div>
            </>
          )}
          {!this.state.showResendButton && (
            <div className="text-center mb-3 d-none">
              <Timer
                initialTime={10000}
                direction="backward"
                timeToUpdate={500}
                checkpoints={[
                  {
                    time: 0,
                    callback: () => this.setState({ showResendButton: true }),
                  },
                ]}
              >
                <Timer.Seconds />
              </Timer>
            </div>
          )}

          <input
            name={"pin"}
            //className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            ref={this.textInput}
            autoFocus={true}
            placeholder={"Enter your pin code here"}
            value={this.state.pin}
            onChange={this.loginValuesChange}
            onKeyPress={this.handleKeypress}
            autoComplete="off"
            style={{
              width: 100 + "%",
              textAlign: "left",
              backgroundColor: "white",
              borderBottomColor: "lightgray",
              paddingLeft: "10px",
              paddingBottom: 8 + "px",
              borderRadius: 0,
              height: "65px",
              border: "none",
              background: "#F8F7F7",
              outline: "none",
              fontSize: "1.6rem",
            }}
            maxLength={`${this.state.lengthOfPin}`}
            // max="6"
          />

          <p className="message mt-3 text-danger text-center">
            {this.state.message}
          </p>

          <input type="hidden" name="flow" value={this.state.task} />
          {Globals.login.SubscriptionPackages && !this.state.pinSent ? (
            <div className="radio">
              <label className="radio-inline">
                <input
                  type="radio"
                  value="daily"
                  name="radioB"
                  defaultChecked
                  onChange={this.setSubscriptionPackage}
                  autoComplete="off"
                />{" "}
                Daily{" "}
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  value="monthly"
                  name="radioB"
                  onChange={this.setSubscriptionPackage}
                  autoComplete="off"
                />{" "}
                Monthly{" "}
              </label>
            </div>
          ) : (
            ""
          )}

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.login}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>

          <h2 className="text-center mt-5"> Didn’t receive Code? </h2>
          <h1
            className={
              "text-center mt-2 " +
              (this.state.showResendButton
                ? "clickable-text"
                : "unclickable-text")
            }
            onClick={() => {
              this.state.showResendButton && this.sendPinAgain();
            }}
          >
            {" "}
            Resend Pin{" "}
          </h1>

          <p
            className={"text-center w-50"}
            style={{ margin: "15px auto 0 auto" }}
          >
            {this.state.pinMessage}
          </p>

          {!Globals.telco &&
          Globals.telcos &&
          this.state.telcoSubMessage.length > 0 ? (
            <p
              className={"text-center w-50 font-weight-bold"}
              style={{ margin: "0 auto 0 auto" }}
            >
              {this.state.telcoSubMessage}
            </p>
          ) : null}

          {!Globals.telco && Globals.telcos ? (
            <p
              className={"text-center w-50" + this.state.telcoMessageClass}
              style={{
                margin: `${
                  this.state.telcoSubMessage.length > 0 ? "30" : "15"
                }px auto 0 auto`,
              }}
            >
              {this.state.telcoMessage}
            </p>
          ) : (
            <p
              className={"text-center w-50"}
              style={{ margin: "15px auto 0 auto" }}
            >
              {Globals.login.price_points ? Globals.login.price_points : ""}
            </p>
          )}
        </div>
      </div>
    );
  };

  renderSubscription = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.8rem", fontWeight: "800" }}
        >
          {" "}
          Select Subscription Method{" "}
        </div>
        <h6 className="text-center mb-5">
          {" "}
          {this.state.phone}{" "}
          <span
            onClick={() => this.setFlowToRender("resendPin")}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <img src={getCDNUrl("/images/edit.svg")} alt="" />
          </span>
        </h6>
        <div className="mb-1" style={{ fontSize: "1.5rem", fontWeight: "600" }}>
          Subscribe With Your Carrier
        </div>
        {this.state.userTelcoPricePoints &&
        this.state.userTelcoPricePoints.length
          ? this.state.userTelcoPricePoints.map((userTelcoPricePoint) => {
              return (
                <div
                  className={"mt-2 mb-4"}
                  id="bundle-box"
                  onClick={() =>
                    this.setState(
                      {
                        userTelcoPricePointSelected: userTelcoPricePoint,
                      },
                      () => this.subscribeWithTelco()
                    )
                  }
                >
                  <div
                    key={
                      userTelcoPricePoint.price + userTelcoPricePoint.sub_type
                    }
                    className="d-flex overflow-x-aut0 col-12"
                  >
                    <span className="col-4">
                      <img
                        className="telco-logo-image"
                        src={this.state.userTelcoLogo}
                        alt=""
                      />
                    </span>
                    <span className="mt-3 col-7 telco-price-point">
                      {userTelcoPricePoint.price}
                    </span>
                    <span className="mt-3 col-1 telco-price-point">
                      <i className="fa fa-chevron-right" />
                    </span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  renderEPBundles = () => {
    return (
      <div>
        <div className="text-center mb-3">
          <span className="">
            <img
              src={getCDNUrl("/images/easypaisa/easypaisa_logo.svg")}
              alt=""
            />
          </span>
        </div>
        <h6 className="text-center mb-3">
          A PIN code will be sent via SMS to following number
        </h6>
        <h6 className="text-center mb-5">
          {" "}
          {this.state.phone}{" "}
          <span
            onClick={() => this.setFlowToRender("resendPin")}
            style={{ cursor: "pointer" }}
          >
            <img src={getCDNUrl("/images/edit.svg")} alt="" />
          </span>
        </h6>
        <EasyPaisaBundles onBundleSelected={this.onEasyPaisaBundleSelected} />
        <button
          className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={this.login}
          disabled={this.state.btnDisbaled}
        >
          {this.state.btnTxt}
        </button>
      </div>
    );
  };

  render() {
    return this.state.isHE ? (
      this.renderHELogin()
    ) : (
      <div id="main-section">
        {this.props.pathname === "/fantasy" ? (
          <>
            <Helmet />
            {Globals.product === "Cricwick" && (
              <Helmet>
                <title>
                  Play PSL Fantasy League for free and Win Exciting Cash Prizes
                  - CricWick
                </title>
                <meta
                  name="description"
                  content={
                    "Play PSL Fantasy League for free. Create your team today, beat the competition and win exciting cash prizes on MyStars11, only on CricWick"
                  }
                />
                <link rel="canonical" href={canonicalLink()} />
              </Helmet>
            )}
          </>
        ) : this.props.pathname === "/wallet" ? (
          <Helmet>
            <link rel="canonical" href={canonicalLink()} />
          </Helmet>
        ) : (
          ""
        )}
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
              {this.state.showNewSubscriptionFlow ? (
                <SubscriptionPlans
                  onPlanSelection={(plan, paymentGateway) =>
                    this.setState(
                      {
                        plan,
                        paymentGateway,
                        showNewSubscriptionFlow: false,
                        showInputFlow: true,
                        gPayFlow:
                          plan.payment_gateways[paymentGateway].title === "GPay"
                            ? true
                            : false,
                      },
                      () => this.scrollToTop()
                    )
                  }
                  onTelcoCardClick={() =>
                    this.setState(
                      {
                        showNewSubscriptionFlow: false,
                        showInputFlow: true,
                      },
                      () => this.scrollToTop()
                    )
                  }
                />
              ) : this.state.showInputFlow &&
              this.Countries[this.state.selectedCountry].cc === "pk" ? (
                this.renderLoginFlowNew()
              ) : this.state.showLoginFlow ? (
                this.renderLogin()
              ) : this.state.showInputFlow ? (
                this.renderLoginFlow()
              ) : this.state.showPinFlow ? (
                this.renderPin()
              ) : this.state.showSubscriptionFlow ? (
                this.renderSubscription()
              ) : this.state.showEPBundle ? (
                this.renderEPBundles()
              ) : null}
            </div>
            {this.state.showEPLoaderModal && (
              <EasypaisaLoader
                errorMessage={this.state.messageSentToLoader}
                onLoaderClose={this.closeLoader}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(LoginForCricwick);
