import React from "react";

const PrivacyPolicy = () => {
  return (
    <section
      id="main-section"
      className="px-lg-0 jc-inner-page  align-self-start "
    >
      <div className="privacy-policy-section">
        <h1>Privacy Policy</h1>
        <h2>Application Privacy Statement</h2>
        <p>
          This privacy statement (“Privacy Statement”) applies to the treatment
          of personally identifiable information submitted by, or otherwise
          obtained from, you in connection with the associated application
          (“Application”). The Application is provided by Khaleef (and may be
          provided by Khaleef on behalf of a Khaleef licensor or partner
          (“Application Partner”)). By using or otherwise accessing the
          Application, you acknowledge that you accept the practices and
          policies outlined in this Privacy Statement.
        </p>
        <h2>What Personal Information Does Khaleef Collect?</h2>
        <p>
          {" "}
          We collect the following types of information from our users:
          <br />
        </p>
        <ul>
          <li>
            <p>
              Personal Information You Provide to Us: We may receive and store
              any information you submit to the Application (or otherwise
              authorize us to obtain – such as, from (for example) your Facebook
              account). The types of personal information collected may include
              your full name, email address, gender, IP address, browser
              information, username, demographic information, and any other
              information necessary for us to provide the Application services.
            </p>
          </li>
          <li>
            <p>
              Personal Information Collected Automatically: We receive and store
              certain types of usage related information whenever you interact
              with Application. For example, Khaleef may automatically receive
              and record information regarding your computer’s IP address,
              browser information, Facebook user ID, Facebook Page fan status,
              and URLs accessed. Such information may be shared in aggregate
              (non-personally identifiable) form with our partners
            </p>
          </li>
        </ul>
        <h2>How Does Khaleef Use the Information It Collects?</h2>
        <p>
          {" "}
          Khaleef uses the information described in this Privacy Statement
          internally, to analyze, develop and improve its products and services,
          and (ii) as set forth below in the “Will Khaleef Share any of the
          Personal Information It Collects” section.
        </p>
        <h2>Application Partner Treatment of Personal Information</h2>
        <p>
          Khaleef may provide personal information to the applicable Application
          Partner. The Application Partner’s use of your personal information is
          subject to the Application Partner’s separate privacy policy – and not
          this Privacy Statement. The Application Partner’s privacy policy is
          linked to from within the Partner’s Facebook application
        </p>
        <h2>Will Khaleef Share Any of the Personal Information It Receives?</h2>
        <p>
          Personal information about our users is an integral part of our
          business. We neither rent nor sell your personal information to
          anyone. We share your personal information only as described below:
          <br />
        </p>
        <ul>
          <li>
            <p>
              Application Partners: We will share your personal information with
              an applicable Application Partner.
            </p>
          </li>
          <li>
            <p>
              Agents: We employ other companies and people to perform tasks on
              our behalf and need to share your information with them to provide
              products or services to you. Unless we tell you differently,
              Khaleef’s agents do not have any right to use personal information
              we share with them beyond what is necessary to assist us.
            </p>
          </li>
          <li>
            <p>
              Business Transfers: In some cases, we may choose to buy or sell
              assets. In these types of transactions, customer information is
              typically one of the business assets that is transferred.
              Moreover, if Khaleef, or substantially all of its assets were
              acquired, or in the unlikely event that Khaleef goes out of
              business or enters bankruptcy, user information would be one of
              the assets that is transferred or acquired by a third party. You
              acknowledge that such transfers may occur, and that any acquirer
              of Khaleef may continue to use your personal information as set
              forth in this policy.
            </p>
          </li>
          <li>
            <p>
              Protection of Khaleef and Others: We may release personal
              information when we believe in good faith that release is
              necessary to comply with the law; enforce or apply our conditions
              of use and other agreements; or protect the rights, property, or
              safety of Khaleef, our employees, our users, or others. This
              includes exchanging information with other companies and
              organizations for fraud protection and credit risk reduction.
            </p>
          </li>
          <li>
            <p>
              With Your Consent: Except as set forth above, you will be notified
              when your personal information may be shared with third parties,
              and will be able to prevent the sharing of this information.
            </p>
          </li>
        </ul>
        <h2>Conditions of Use</h2>
        <p>
          If you decide to use or otherwise access the Application, your
          use/access and any possible dispute over privacy is subject to this
          Privacy Statement and our Terms of Use, including limitations on
          damages, arbitration of disputes, and application of California state
          law.
        </p>
        <h2>Third Party Applications/Websites</h2>
        <p>
          The Application may permit you to link to other applications or
          websites. Such third party applications/websites are not under
          Khaleef's control, and such links do not constitute an endorsement by
          Khaleef of those other applications/websites or the services offered
          through them. The privacy and security practices of such third party
          application/websites linked to the Application are not covered by this
          Privacy Statement, and Khaleef is not responsible for the privacy or
          security practices or the content of such websites.
        </p>
        <h2>What Personal Information Can I Access?</h2>
        <p>
          Khaleef allows you to access the following information about you for
          the purpose of viewing, and in certain situations, updating that
          information. This list may change if the Application changes:
          <br />
        </p>
        <ul>
          <li>
            <p>Account and user profile information</p>
          </li>
          <li>
            <p> User email address, if applicable</p>
          </li>
          <li>
            <p> Facebook profile information, if applicable</p>
          </li>
          <li>
            <p> User preferences</p>
          </li>
          <li>
            <p> Application specific data</p>
          </li>
        </ul>
        <h2>Push Notifications</h2>
        <p>
          We may send you push notifications through our mobile applications.
          You may at any time optout from receiving these types of
          communications by changing the settings on your mobile device.
        </p>
        <h2>How We Protect Your Data</h2>
        <p>
          We take your data security very seriously. We have implemented a
          variety of security measures to protect your data from unauthorized
          access, loss, misuse, or alteration. We also retain your data for only
          as long as necessary to provide you with the service
        </p>
        <h2>Deletion, Modification and Data Retention</h2>
        <p>
          {" "}
          We will keep your data as long as you have an active account or as
          long as it is necessary to provide you with the service. If for any
          reason you wish to delete or modify your Personal Information please
          send us an e-mail with adequate detail of your request to:
          <a href="mailto:info@Khaleef.com">info@Khaleef.com</a>
          and we will make reasonable efforts to modify or delete any such
          Personal Information pursuant to any applicable privacy laws.
        </p>
        <h2>Changes to This Privacy Statement</h2>
        <p>
          Khaleef may amend this Privacy Statement from time to time. Use of
          information we collect now is subject to the Privacy Statement in
          effect at the time such information is used. If we make changes in the
          way we use personal information, we will notify you by posting an
          announcement on our Site or sending you an email.
        </p>
        <h2>Questions or Concerns</h2>
        <p>
          If you have any questions or concerns regarding privacy on our
          Website, please send us a detailed message at{" "}
          <a href="mailto:info@Khaleef.com">info@Khaleef.com</a>. We will make
          every effort to resolve your concerns
        </p>
        <p>Effective Date: October 1, 2017</p>
        <p>© Khaleef 2024. All Rights Reserved.</p>
      </div>

      {/* <p><!-- END of Privacy Policy || Generated by http://www.PrivacyPolicyOnline.com || --></p> */}
    </section>
  );
};

export default PrivacyPolicy;
