import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
  Switch,
  Link,
} from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import Maincarousel from "./Maincarousel"; // for firebase
//import Maincarousel from './MainCarousalPusher'; // for pusher
import Leftsection from "./Leftsection";
import CricWickAds from "./CricWickAds";
import Rightsection from "./Rightsection";
import Login from "./Login";
import AllSeries from "./AllSeries";
import Series from "./Series";
import Updates from "./Updates";
import News from "./News";
import Stories from "./Stories";
//import Videos from './Videos';
import Rankings from "./Rankings";
import Odi from "./Odi";
import T20 from "./T20";
import Match from "./Match";
import SeriesSchedules from "./SeriesSchedules";
import SeriesPointsTable from "./SeriesPointsTable";
import Squads from "./Squads";
import SingleVideo from "./SingleVideo";
import StoriesDetail from "./StoriesDetail";
import Authentication from "./Authentication";
import AuthenticationForZain from "./AuthenticationForZain";
import AuthenticationForOmantel from "./AuthenticationForOmantel";
import Cgauth from "./cgauth";
import Home from "./Home";
import Quiz from "./Quiz";
import NotFound404 from "./404";
import {
  canonicalLink,
  getPayWallFlags,
  getRegionFlags,
} from "./HelperFunctions";
import { Globals } from "./constants";
import UnSub from "./unsub";
import { Telco } from "./telco";
import Live from "./Live";
import JazzCallBack from "./login/jazzCallback";
import ReactPixel from "react-facebook-pixel";

import Videos from "./VideosNew";
import Playlists from "./Playlists";

import OnBoarding from "./trivia/onboarding/OnBoarding";
import PaymentMethods from "./trivia/PaymentMethods";
import AppTermsAndConditions from "./TermsAndConditions";

import TelenorFaqs from "./TPfaqs";
import FantasyView from "./fantasy/FantasyView";
import FantasyAuthentication from "./fantasy/FantasyLanding";

import MyStars11TermsAndConditions from "./MyStars11TermsAndConditions";
import MyStars11FAQs from "./MyStars11FAQs";
import SubscriptionPlans from "./login/SubscriptionPlan";
import { Helmet } from "react-helmet";
import InvitedByFriend from "./invitedByFriend";
import UserProfile from "../src/newLoginFlow/userProfile";
import ContestWallet from "./trivia/ContestWallet";
import RedeemPrizeCard from "./redeemPrizeCard";
import AuthenticationForOoredooOman from "./AuthenticationForOoredooOman";
import PrivacyPolicy from "./PrivacyPolicy";
const queryString = require("query-string");

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "",
      selectedMatchId: 0,
      loginCheck: false,
      telco: this.props.userTelco ? this.props.userTelco : "ufone",
      isEasypaisaSubscription: this.props.isEasypaisaSubscription,
      lowBalance:
        Globals.telco !== "mobilink" &&
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).isUserGracePeriodElapsed,
      renderLoginForLowBalance: false,
      payWallFlags: getPayWallFlags(
        Globals.appName,
        Telco,
        localStorage.getItem("countryCode")
      ),
      countryCode: localStorage.getItem("countryCode"),
    };

    this.setCurrentPath = this.setCurrentPath.bind(this);
    this.downloadApp = this.downloadApp.bind(this);
  }

  setCurrentPath(path, state) {
    let paths = path.split("/");
    paths = paths.slice(1, paths.length);
    let matchId = 0;
    if (state && state.matchId) matchId = state.matchId;
    this.setState({ currentPage: "/" + paths[0], selectedMatchId: matchId });
  }

  componentDidMount() {
    this.setCurrentPath(this.props.location.pathname);

    this.props.history.listen((location) => {
      this.setCurrentPath(location.pathname, location.state);
    });

    if (localStorage.getItem("login") === "true") {
      localStorage.setItem("login", false);
      this.props.history.push("/login");
    }

    if (window.location.pathname.includes("match-live-stream")) {
      let pArray = window.location.pathname.split("/");
      let id = pArray[2];
      let url = "https://cricwick.net/match/" + id + "/summary/1";
      window.open(url, "_self");
    }

    if (window.location.pathname.includes("banner")) {
      let url = "https://cricwick.net";
      window.open(url, "_self");
    }

    let path = queryString.parse(this.props.location.search);
    if (!path.sub_source) {
      localStorage.removeItem("sub_source");
    } else {
      if (path.sub_source === "tp" && Globals.telco === "cricwick") {
        ReactPixel.track("track", "subscription");
        window.ga("send", {
          hitType: "event",
          eventCategory: "subscription",
        });
      }
      localStorage.setItem("sub_source", path.sub_source);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryCode !== this.props.countryCode) {
      this.setState({
        payWallFlags: getPayWallFlags(
          Globals.appName,
          Telco,
          this.props.countryCode
        ),
        countryCode: this.props.countryCode,
      });
    }

    if (this.props.userLoggedIn && !prevProps.userLoggedIn) {
      this.setState({
        lowBalance: JSON.parse(localStorage.getItem("user"))
          .isUserGracePeriodElapsed,
      });
    }

    let redirect_after_login = localStorage.getItem("redirect_after_login");
    if (
      this.props.location.pathname === "/" &&
      redirect_after_login &&
      redirect_after_login.length
    ) {
      this.props.history.push(redirect_after_login);
      localStorage.removeItem("redirect_after_login");
      localStorage.removeItem("invitedUser");
    }

    if (
      this.state.renderLoginForLowBalance &&
      this.props.location.pathname !== prevProps.location.pathname
    )
      this.setState({
        renderLoginForLowBalance: false,
      });
  }

  async downloadApp(e) {
    // use ip find here too
    // let u = "https://api.ipfind.com/me?auth=ce3b85c8-c814-4936-8d9a-02d946409e59";
    // let r = (Telco === 'cricwick') ? await fetch(u) : "null";
    // let rp = (Telco === 'cricwick') ? await r.json() : "null";
    let rp = localStorage.getItem("countryCode") || "pk";

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let sUsrAg = navigator.userAgent,
      win,
      url;
    if (iOS) {
      url =
        Telco === "cricwick" ? Globals.appUrl[rp].ios : this.props.appPathIos;
      win = window.open(url);
      //win.focus();
    } else {
      if (sUsrAg.indexOf("Android") > -1) {
        url =
          Telco === "cricwick"
            ? Globals.appUrl[rp].android
            : this.props.marketPath;
        win = window.open(url, "_blank");
        win.focus();
      } else {
        url =
          Telco === "cricwick"
            ? Globals.appUrl[rp].android
            : this.props.marketPath;
        window.open(url);
      }
    }
  }

  render() {
    const isDesktop = window.innerWidth > 992 ? true : false;
    if (window.location.pathname.includes("termsnconditions")) {
      let pArray = window.location.pathname.split("/");
      let operatorName = pArray[2];
      return (
        <div className="container">
          <AppTermsAndConditions operatorName={operatorName} />
        </div>
      );
    }

    if (window.location.pathname.includes("world-cup-quiz-mobile")) {
      let pArray = window.location.pathname.split("/");
      let msisdn = pArray[2];
      return (
        <div className="container">
          <Quiz msisdn={msisdn} />
        </div>
      );
    }

    if (window.location.pathname.includes("faqs.html")) {
      return (
        <div className="container">
          <TelenorFaqs />
        </div>
      );
    }
    if (window.location.pathname.includes("payment-methods")) {
      let pArray = window.location.pathname.split("/");
      let telco = pArray[2];
      let msisdn = pArray[3];
      return (
        <div className="container">
          <PaymentMethods telco={telco} msisdn={msisdn} />
        </div>
      );
    }

    if (window.location.pathname.includes("privacy-policy-mobile")) {
     
      return (
        <div className="container">
          <PrivacyPolicy />
        </div>
      );
    }
    if (window.location.pathname.includes("app-mystars11-faqs")) {
      let pArray = window.location.pathname.split("/");
      let telco = pArray[2];
      let domain = pArray[3];
      return (
        <div className="container">
          <MyStars11FAQs telco={telco} domain={domain} />
        </div>
      );
    }
    if (window.location.pathname.includes("app-mystars11-tnc")) {
      let pArray = window.location.pathname.split("/");
      let telco = pArray[2];
      let domain = pArray[3];
      return (
        <div className="container">
          <MyStars11TermsAndConditions telco={telco} domain={domain} />
        </div>
      );
    }

    return (
      <div>
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        <Router>
          <>
            <Nav currentPage={this.state.currentPage} {...this.props} />

            <Maincarousel
              selectedMatchId={this.state.selectedMatchId}
              telco={this.state.telco}
            />

            <div id="page" className="container py-2">
              <div className="row">
                {<OnBoarding onMBLEnabled={this.onMBLEnabled} />}
                {isDesktop ? (
                  <Leftsection
                    userLoggedIn={this.props.userLoggedIn}
                    telco={this.state.telco}
                  />
                ) : (
                  ""
                )}

                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => <Home telco={this.state.telco} />}
                  />

                  <Route
                    path="/world-cup-quiz"
                    exact
                    render={(props) => 
                      this.props.userLoggedIn ? (
                        <Quiz telco={this.state.telco} />
                      ) : (
                        <Login
                              downloadApp={this.downloadApp}
                              {...props}
                              msisdn={this.props.msisdn}
                            />
                      )}
                  />

                  <Route path="/privacy-policy" exact render={() => <PrivacyPolicy />}
                  />

                  <Route
                    path="/subscription"
                    exact
                    render={() => <SubscriptionPlans />}
                  />
                  <Route
                    path="/invited-by-friend/createTeam/:inviteCode/:subscriptionStatus/:seriesId/:leagueId/:matchId/:constantId/:selection"
                    exact
                    render={(props) => (
                      <InvitedByFriend
                        {...props}
                        userLoggedIn={this.props.userLoggedIn}
                      />
                    )}
                  />

                  <Route
                    path="/invited-by-friend/fantasyHome/:inviteCode"
                    exact
                    render={(props) =>
                      this.props.userLoggedIn ? (
                        <Redirect to="/" />
                      ) : (
                        <InvitedByFriend
                          {...props}
                          userLoggedIn={this.props.userLoggedIn}
                        />
                      )
                    }
                  />
                  <Route
                    path="/login/:isMena?/:appName?/:filledNumber?"
                    exact
                    render={(props) =>
                      this.props.userLoggedIn ? (
                        <Redirect to="/" />
                      ) : (
                        <Login
                          {...props}
                          downloadApp={this.downloadApp}
                          msisdn={this.props.msisdn}
                        />
                      )
                    }
                  />

                  <Route
                    path="/redeemprize"
                    exact
                    render={(props) => <RedeemPrizeCard {...props} />}
                  />

                  <Route
                    path="/jazzcash/callback"
                    exact
                    render={(props) =>
                      this.props.userLoggedIn ? (
                        <Redirect to="/" />
                      ) : (
                        <JazzCallBack
                          {...props}
                          downloadApp={this.downloadApp}
                          msisdn={this.props.msisdn}
                        />
                      )
                    }
                  />

                  <Route path="/:telco/unsub" exact component={UnSub} />

                  <Route
                    path="/tnc/:operator"
                    exact
                    render={(props) => <AppTermsAndConditions {...props} />}
                  />

                  <Route
                    path="/user-profile"
                    exact
                    render={(props) => <UserProfile {...props} />}
                  />
                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/mystars11-tnc"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <MyStars11TermsAndConditions {...props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/mystars11-tnc"
                      exact
                      render={(props) => (
                        <MyStars11TermsAndConditions {...props} />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/mystars11-faqs"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <MyStars11FAQs {...props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/mystars11-faqs"
                      exact
                      render={(props) => <MyStars11FAQs {...props} />}
                    />
                  )}

                  {/* <Route path="/rankings" exact component={Rankings} /> */}
                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/rankings/test"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Rankings />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/rankings/test"
                      exact
                      render={(props) => <Rankings />}
                    />
                  )}

                  <Route
                    path="/rankings/t20"
                    exact
                    render={() => <T20 filter={"t20"} />}
                  />
                  <Route
                    path="/rankings/odi"
                    exact
                    render={() => <Odi filter={"odi"} />}
                  />

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/updates/news"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Updates loadData={false} type="news" />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/updates/news"
                      exact
                      render={(props) => (
                        <Updates loadData={false} type="news" />
                      )}
                    />
                  )}

                  <Route
                    path="/updates/articles"
                    exact
                    render={() => <News loadData={false} type="articles" />}
                  />
                  {/* <Route path="/updates/articles" exact  render={() => <Updates loadData={false} type="articles" />} /> */}
                  {/* <Route path="/updates/articles" exact component={Updates} /> */}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Series {...props} telco={this.state.telco} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title"
                      exact
                      render={(props) => (
                        <Series {...props} telco={this.state.telco} />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/live-cricket-score/:state"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <AllSeries {...props} />
                        ) : (
                          <Login />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/live-cricket-score/:state"
                      exact
                      render={(props) => <AllSeries {...props} />}
                    />
                  )}
                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title/schedules"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <SeriesSchedules
                            {...props}
                            telco={this.state.telco}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title/schedules"
                      exact
                      render={(props) => (
                        <SeriesSchedules {...props} telco={this.state.telco} />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title/pointstable"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <SeriesPointsTable {...props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title/pointstable"
                      exact
                      render={(props) => <SeriesPointsTable {...props} />}
                    />
                  )}
                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title/squads"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Squads {...props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title/squads"
                      exact
                      render={(props) => <Squads {...props} />}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title/videos"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <SingleVideo
                            {...props}
                            telco={this.state.telco}
                            isVideoAllowed={this.props.isVideoAllowed}
                            isRegionChecked={this.props.isRegionChecked}
                            noVideoReason={this.props.noVideoReason}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title/videos"
                      exact
                      render={(props) => (
                        <SingleVideo
                          {...props}
                          telco={this.state.telco}
                          isVideoAllowed={this.props.isVideoAllowed}
                          isRegionChecked={this.props.isRegionChecked}
                          noVideoReason={this.props.noVideoReason}
                        />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/series/:id/:title/videos/:videoId/:title"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Route
                            path="/series/:id/:title/videos/:videoId/:title"
                            exact
                            render={(props) => (
                              <SingleVideo
                                {...props}
                                telco={this.state.telco}
                                isVideoAllowed={this.props.isVideoAllowed}
                                isRegionChecked={this.props.isRegionChecked}
                                noVideoReason={this.props.noVideoReason}
                              />
                            )}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/series/:id/:title/videos/:videoId/:title"
                      exact
                      render={(props) => (
                        <SingleVideo
                          {...props}
                          telco={this.state.telco}
                          isVideoAllowed={this.props.isVideoAllowed}
                          isRegionChecked={this.props.isRegionChecked}
                          noVideoReason={this.props.noVideoReason}
                        />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/video/:videoId/:title"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <SingleVideo
                            {...props}
                            telco={this.state.telco}
                            msisdn={this.props.msisdn}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/video/:videoId/:title"
                      exact
                      render={(props) => (
                        <SingleVideo
                          {...props}
                          telco={this.state.telco}
                          msisdn={this.props.msisdn}
                        />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/video/highlights/:videoId/:title"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <SingleVideo
                            {...props}
                            telco={this.state.telco}
                            msisdn={this.props.msisdn}
                            isHighlights={true}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/video/highlights/:videoId/:title"
                      exact
                      render={(props) => (
                        <SingleVideo
                          {...props}
                          telco={this.state.telco}
                          msisdn={this.props.msisdn}
                          isHighlights={true}
                        />
                      )}
                    />
                  )}

                  <Route
                    path="/playlist/:playlistId/video/:videoId/:title"
                    exact
                    render={(props) => (
                      <SingleVideo
                        {...props}
                        telco={this.state.telco}
                        msisdn={this.props.msisdn}
                      />
                    )}
                  />

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/stories"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Stories loadData={true} type="stories" />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/stories"
                      exact
                      render={() => <Stories loadData={true} type="stories" />}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/stories/:id/:title"
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <StoriesDetail type="stories" match={props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/stories/:id/:title"
                      render={(match) => (
                        <StoriesDetail type="stories" match={match} />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/articles/:id/:title"
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <StoriesDetail type="articles" match={props} />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/articles/:id/:title"
                      render={(match) => (
                        <StoriesDetail type="articles" match={match} />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/news/:id/:title"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <StoriesDetail
                            type="news"
                            news_title="pakistan"
                            news_image="https://scontent.flhe6-1.fna.fbcdn.net/v/t1.0-9/67354424_2898218010195478_33245086980505600_n.jpg?_nc_cat=100&_nc_eui2=AeGoW1t2jg4rAgB4q2_fxUnz_tCaj_rJxXUj8y34zsbTN_GdKa5U_ZTDhcHmMTul-SzOSDRXUJcweP-NnvvNJ8v_xymHqPTb1gY6cDkRZaRpeA&_nc_oc=AQnZuxyvr1frzD2GcMZOFTrbDEQAUoOEvZerVipYoifEpyTvscaPo0_804vW0BtM6rc&_nc_ht=scontent.flhe6-1.fna&oh=e437a8c94c0934847f32551f2da93bde&oe=5DA440B7"
                            news_detail="anything"
                            match={props}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/news/:id/:title"
                      exact
                      render={(match) => (
                        <StoriesDetail
                          type="news"
                          news_title="pakistan"
                          news_image="https://scontent.flhe6-1.fna.fbcdn.net/v/t1.0-9/67354424_2898218010195478_33245086980505600_n.jpg?_nc_cat=100&_nc_eui2=AeGoW1t2jg4rAgB4q2_fxUnz_tCaj_rJxXUj8y34zsbTN_GdKa5U_ZTDhcHmMTul-SzOSDRXUJcweP-NnvvNJ8v_xymHqPTb1gY6cDkRZaRpeA&_nc_oc=AQnZuxyvr1frzD2GcMZOFTrbDEQAUoOEvZerVipYoifEpyTvscaPo0_804vW0BtM6rc&_nc_ht=scontent.flhe6-1.fna&oh=e437a8c94c0934847f32551f2da93bde&oe=5DA440B7"
                          news_detail="anything"
                          match={match}
                        />
                      )}
                    />
                  )}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/videos"
                      exact
                      render={(props) =>
                        this.props.userLoggedIn ? (
                          <Videos
                            {...props}
                            telco={this.state.telco}
                            type="allVideos"
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/videos"
                      exact
                      render={(props) => (
                        <Videos
                          {...props}
                          telco={this.state.telco}
                          type="allVideos"
                        />
                      )}
                    />
                  )}
                  <Route
                    path="/playlists"
                    exact
                    render={(props) => <Playlists {...props} />}
                  />

                  <Route
                    path="/videos/featured"
                    exact
                    render={(props) => (
                      <Videos
                        {...props}
                        telco={this.state.telco}
                        type="featured"
                      />
                    )}
                  />
                  <Route
                    path="/videos/archives"
                    exact
                    render={(props) => (
                      <Videos
                        {...props}
                        telco={this.state.telco}
                        type="archives"
                      />
                    )}
                  />

                  <Route
                    path="/authentication"
                    exact
                    component={Authentication}
                  />
                  <Route
                    path="/omantel-authentication"
                    exact
                    component={AuthenticationForOmantel}
                  />

                  <Route
                    path="/ooredoo-oman-authentication"
                    exact
                    component={AuthenticationForOoredooOman}
                  />

                  <Route
                    path="/authentication_zain"
                    exact
                    component={AuthenticationForZain}
                  />
                  <Route
                    path="/cgauth"
                    exact
                    render={(props) => (
                      <Cgauth {...props} downloadApp={this.downloadApp} />
                    )}
                  />

                  <Route
                    path="/fantasy-auth/:token+/:msisdn/:telco"
                    exact
                    render={(props) => (
                      <FantasyAuthentication
                        {...props}
                        downloadApp={this.downloadApp}
                      />
                    )}
                  />

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/match/:id/:category/:subcategory/:slug"
                      exact
                      render={(props) =>
                        // this.props.userLoggedIn ? (
                        //   <Match
                        //     {...props}
                        //     isVideoAllowed={this.props.isVideoAllowed}
                        //     telco={this.state.telco}
                        //     isRegionChecked={this.props.isRegionChecked}
                        //     noVideoReason={this.props.noVideoReason}
                        //   />
                        // ) : (
                        //   <Login
                        //     downloadApp={this.downloadApp}
                        //     {...props}
                        //     msisdn={this.props.msisdn}
                        //   />
                        // )
                        this.props.userLoggedIn ? (
                          <Match
                            {...props}
                            isVideoAllowed={this.props.isVideoAllowed}
                            telco={this.state.telco}
                            isRegionChecked={this.props.isRegionChecked}
                            noVideoReason={this.props.noVideoReason}
                            userLoggedIn={this.props.userLoggedIn}
                            lowBalance={this.state.lowBalance}
                          />
                        ) : (
                          <Login
                            downloadApp={this.downloadApp}
                            {...props}
                            msisdn={this.props.msisdn}
                          />
                        )
                      }
                    />
                  ) : (
                    <Route
                      path="/match/:id/:category/:subcategory/:slug"
                      exact
                      render={(props) => (
                        <Match
                          {...props}
                          isVideoAllowed={this.props.isVideoAllowed}
                          telco={this.state.telco}
                          isRegionChecked={this.props.isRegionChecked}
                          noVideoReason={this.props.noVideoReason}
                          userLoggedIn={this.props.userLoggedIn}
                          lowBalance={this.state.lowBalance}
                        />
                      )}
                    />
                  )}
                  {/* <Redirect from='*' to='/404notfound' /> */}
                  {/* </Switch> */}

                  <Route path="/dugout-live" exact render={() => <Live />} />
                  {/* <Route path="/games" exact render={() => <Games />} />
            <Route
              path="/games/:id"
              exact
              render={(match, props) =>
                this.props.userLoggedIn ? (
                  <PlayGame match={match} />
                ) : (
                  <Login
                    downloadApp={this.downloadApp}
                    {...props}
                    msisdn={this.props.msisdn}
                  />
                )
              }
            /> */}

                  {/* TRIVIA ROUTES START*/}

                  <Route
                    path="/wallet"
                    exact
                    render={(props) =>
                      this.props.userLoggedIn ? (
                        //<Wallet />
                        <ContestWallet {...props} />
                      ) : (
                        <Login
                          downloadApp={this.downloadApp}
                          {...props}
                          msisdn={this.props.msisdn}
                        />
                      )
                    }
                  />

                  {/* TRIVIA ROUTES END */}

                  {/* <Route
              path="/fantasy"
              exact
              render={
                (props) =>
                  
                      <FantasyView {...props} msisdn={this.props.msisdn} />
                 
                // this.props.userLoggedIn ? (
                //   <FantasyView {...props} msisdn={this.props.msisdn} />
                // ) : (
                //   <Login
                //     downloadApp={this.downloadApp}
                //     {...props}
                //     msisdn={this.props.msisdn}
                //   />
                // )
              }
              
            /> */}

                  {/* {Globals.telco == "mobilink" || Globals.telco == "zain" || (localStorage.getItem("countryCode") && localStorage.getItem("countryCode").toLowerCase() == "sa") ? ( */}
                  {this.state.payWallFlags.payWallOnFantasy ? (
                    <Route
                      path="/fantasy/:isInvite?"
                      exact
                      render={
                        (props) =>
                          this.props.userLoggedIn ? (
                            <FantasyView
                              {...props}
                              msisdn={this.props.msisdn}
                            />
                          ) : (
                            <Login
                              downloadApp={this.downloadApp}
                              {...props}
                              msisdn={this.props.msisdn}
                            />
                          )
                        // this.props.userLoggedIn ? (
                        //   <FantasyView {...props} msisdn={this.props.msisdn} />
                        // ) : (
                        //   <Login
                        //     downloadApp={this.downloadApp}
                        //     {...props}
                        //     msisdn={this.props.msisdn}
                        //   />
                        // )
                      }
                    />
                  ) : (
                    <Route
                      path="/fantasy/:isInvite?"
                      exact
                      render={(props) => (
                        <FantasyView {...props} msisdn={this.props.msisdn} />
                      )}
                    />
                  )}

                  <Route component={NotFound404} noindex={true} />
                </Switch>

                {isDesktop ? (
                  <Rightsection
                    downloadApp={this.downloadApp}
                    telco={this.state.telco}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <Footer downloadApp={this.downloadApp} />
          </>
        </Router>
      </div>
    );
  }
}

export default withRouter(Routes);
