import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/css/public.css";
import HttpsApp from "./HttpsApp";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<HttpsApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
if ("serviceWorker" in navigator) {
navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
        console.log("Registration successful, scope is:", registration);
    })
    .catch(function(err) {
    console.log("Service worker registration failed, error:", err);
    });
}
